import {
    CreateBankAccountRequest,
    CreateBankAccountResponse,
    DeleteBankAccountResponse,
    GetBankAccountsRequest,
    GetBankAccountsResponse,
    UpdateBankAccountRequest,
    UpdateBankAccountResponse,
} from './types';
import { ObjectKeys } from '../../types/objectKeys';

import fetch from '../fetch';
import getCurrentLanguage from '../../functions/getCurrentLanguage';
import routes from '../routes';

export default class BankAccounts {
    public getBankAccounts(params: GetBankAccountsRequest): Promise<GetBankAccountsResponse> {
        return fetch({
            method: 'GET',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.bankAccounts.get,
            params: params,
        });
    }
    public createBankAccount(data: any): CreateBankAccountResponse {
        return fetch({
            method: 'POST',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.bankAccounts.create,
            data: data,
        });
    }
    public updateBankAccount(data: UpdateBankAccountRequest, bank_id: string): UpdateBankAccountResponse {
        return fetch({
            method: 'PUT',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.bankAccounts.update(bank_id),
            data: data,
        });
    }
    public deleteBankAccount(bank_id: string): DeleteBankAccountResponse {
        return fetch({
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.bankAccounts.delete(bank_id),
            data: { id: bank_id },
        });
    }
    public setDefaultAccount(account_id: string): ObjectKeys {
        return fetch({
            method: 'PUT',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.bankAccounts.setDefault,
            data: { id: account_id },
        });
    }
}
