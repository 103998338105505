import useTranslations from '../../../hooks/useTranslation';
import { FunctionComponent } from 'react';
import styled from 'styled-components';
import useLanguageStore from '../../../store/language';
import { useNavigate } from 'react-router-dom';
import SkeletonLimits from './skeleton';
import { MQ_BREAKPOINTS } from '../../../constants/breakpoints';

interface SingleItemProps {
    limit: string;
    value: string;
    rest: string;
    isLoading: boolean;
}

const SingleItem: FunctionComponent<SingleItemProps> = ({ limit, value, rest, isLoading }) => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    const navigate = useNavigate();

    const handleNavigateToStatistic = (): void => {
        navigate('/reports');
    };

    return (
        <>
            {isLoading ? (
                <SkeletonLimits />
            ) : (
                <Wrapper onClick={handleNavigateToStatistic}>
                    <TopSection>{limit}</TopSection>
                    <BottomSection>
                        <Value>{value}</Value>
                        <RestToLimit>
                            {t('pages.invoices.statistics.restToLimit').text}: {rest}
                        </RestToLimit>
                    </BottomSection>
                </Wrapper>
            )}
        </>
    );
};

export default SingleItem;

const Wrapper = styled.div`
    width: 45%;
    height: 150px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    cursor: pointer;
    @media only screen and (max-width: ${MQ_BREAKPOINTS.mobileL}) {
        width: 100%;
    }
`;

const TopSection = styled.span`
    width: 100%;
    height: 20%;
    background-color: var(--purple);
    display: flex;
    align-items: center;
    color: white;
    justify-content: center;
    text-transform: uppercase;
`;
const BottomSection = styled.div`
    width: 100%;
    height: 78%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border: 1px solid var(--purple);
    flex-direction: column;
`;
const Value = styled.div`
    font-size: 30px;
    color: var(--purple);
`;

const RestToLimit = styled.span`
    font-size: 15px;
`;
