import { FunctionComponent, useEffect, useRef, useState } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import styled from 'styled-components';
import communication from '../../../../communication';
import Button, { ButtonVariant } from '../../../../components/button';
import { useLocation, useNavigate } from 'react-router-dom';
import QuillToolbar from '../toolbar';
import Input from '../../../../components/input';
import colors from '../../../../global/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faEnvelope, faPrint, faSave } from '@fortawesome/free-solid-svg-icons';
import Loader from '../../../../components/loader';
import useAgencyStore from '../../../../store/agency';
import useLanguageStore from '../../../../store/language';
import useTranslations from '../../../../hooks/useTranslation';
import Sidebar from '../../../../components/sideModal';
import SendDocumentModal from '../../../myAgency/documents/sendModal';
import { SendDocumentFormData } from '../../../../types/communication/document';
import useMemoStore from '../../../../store/memos';
import { ObjectKeys } from '@/types/objectKeys';
import { MQ_BREAKPOINTS } from '../../../../constants/breakpoints';

enum MemorandumStatus {
    sent = 'sent',
    preparation = 'preparation',
}

const NewMemorandum: FunctionComponent = () => {
    const [memorandum, setMemorandum] = useState<any>();
    const agency = useAgencyStore((props) => props.agency);
    const [editorValue, setEditorValue] = useState<any>();
    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const [memorandumName, setMemorandumName] = useState<string>('');
    const [isLoader, setIsLoader] = useState<boolean>(false);
    const token = localStorage.getItem('access_token');
    const quillRef = useRef<any>();
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    const { state } = useLocation();
    const { name, template } = state;
    const navigate = useNavigate();
    const appUrl = process.env.REACT_APP_URL;
    const logoUrl = agency.logo ? `${appUrl}/${agency.logo}` : null;
    const [renderKey, setRenderKey] = useState<number>(0);
    const [sendData, setSendData] = useState<SendDocumentFormData>({
        recipient: '',
        subject: '',
        message: '',
    });

    const domesticBankAccounts =
        agency.bankAccounts.data.length > 0
            ? agency.bankAccounts.data
                  .filter((account: ObjectKeys) => account.type === 'domestic')
                  .map((account: ObjectKeys) => ({
                      value: account,
                  }))
            : [];

    //QUILL Editor Configuration
    const Size = Quill.import('attributors/style/size');
    Size.whitelist = ['12pt', '14pt', '16pt', '18pt', '20pt', '22pt', '24pt', '26pt', '28pt'];
    const hr = Quill.import('blots/block/embed');
    const Block = Quill.import('blots/block');
    Block.tagName = 'DIV';
    Quill.register(Block, true);
    hr.blotName = 'divider';
    hr.tagName = 'hr';
    Quill.register(Size, true);
    const Break = Quill.import('blots/break');
    Break.blotName = 'break';
    Break.tagName = 'BR';
    Quill.register(Block, true);
    Quill.register({
        'formats/hr': hr,
    });

    const formats = [
        'header',
        'font',
        'size',
        'bold',
        'indent',
        'italic',
        'align',
        'list',
        'bullet',
        'image',
        'divider',
        'link',
    ];
    const getMyAgency = async (): Promise<void> => {
        const response = await communication.getAgency();
        getTemplate(response.data.data);
    };

    useEffect(() => {
        setIsLoader(true);
        setMemorandumName(name);
        getMyAgency();
        setIsLoader(false);
    }, []);

    const MemorandumHeader = (): string =>
        `<p class="ql-align-center">${logoUrl ? `<img src=${logoUrl}?renderKey=${renderKey} />` : ''}
        </p><h1 class="ql-align-center"><div style={{ fontSize: '22pt' }}>${
            agency?.full_name ? agency?.full_name : ''
        }</div></h1><p class="ql-align-center"><div class='ql-align-center' style={{ fontSize: '12pt' }}>${
            agency?.street_address ? agency.street_address : ''
        } ${agency?.street_number ? agency.street_number + ',' : ''} ${agency?.city ? agency.city : ''} ${'PIB'}: ${
            agency?.pib ? agency?.pib : ''
        } MB : ${
            agency?.identification_number ? agency?.identification_number : ''
        }</div></p><p class={'ql-align-center '}><div class='ql-align-center' style={{ fontSize: ' 12pt' }}>${
            agency?.agency_email ? agency?.agency_email : ''
        }</div></p><p class="ql-align-center"><div class='ql-align-center' style={{ fontSize: '12pt' }}> Telefon: ${
            agency?.phone ? agency?.phone : ''
        }</div></p><hr class="ql-hr" style={{height: '0px';marginTop: '10px';marginBottom: '10px'}}/>`;

    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const getTemplate = (data: any) => {
        switch (template) {
            case '2':
                return setEditorValue(
                    `${MemorandumHeader()}<br><h1 class='ql-align-center'><div style='font-size: 18pt;'>Zahtev</div></h1><br><br><p class='ql-align-center'>Za preduzetničku agenciju ${
                        data?.full_name
                    } želim da otkupim devize u iznosu od: IZNOS VALUTA</p><p class='ql-align-center'>Uplatu izvršiti na račun br.: ${
                        domesticBankAccounts[0].value.account_number
                    }</p><br><br><br><br><br><br><br><p>S poštovanjem,<br>________________<p><br>M.P.`,
                );
            case '3':
                return setEditorValue(
                    `${MemorandumHeader()}<br><h1 class='ql-align-center'><div style='font-size: 18pt;'>Ponuda</div></h1><br><p><div style='color: rgb(0, 0, 0); font-size: 12pt;'>Komitent</div><br><em style='color: rgb(153, 153, 153); font-size: 12pt;'>Naziv</em><br><br><em style='color: rgb(153, 153, 153); font-size: 12pt;'>Adresa</em><br><br><em style='color: rgb(153, 153, 153); font-size: 12pt;'>Grad</em><br><br><em style='color: rgb(153, 153, 153); font-size: 12pt;'>PIB </em></p><br><br><p><div style='color: rgb(0, 0, 0); font-size: 12pt; display: inline'>Ponuda za obavljanje</div><em style='color: rgb(102, 102, 102); font-size: 12pt;'> [opis posla]</em><div style='color: rgb(0, 0, 0); font-size: 12pt; display: inline'> iznosi </div><em style='color: rgb(102, 102, 102); font-size: 12pt; display: inline'>[iznos i valuta] </em><div style='color: rgb(0, 0, 0); font-size: 12pt; display: inline'>po [satu, danu…].</div></p><br><br><p><div style='color: rgb(0, 0, 0); font-size: 12pt;'>Ukoliko ste saglasni sa ponudom, na ime dogovorenog posla, uplatiti </div><em style='color: rgb(102, 102, 102); font-size: 12pt;'>[ukupan iznos i valuta]</em><div style='color: rgb(0, 0, 0); font-size: 12pt;'> na žiro račun: ${
                        domesticBankAccounts[0].value.account_number
                    }.</div><br><div style='color: rgb(0, 0, 0); font-size: 12pt;'>Prihvatanje ponude se realizuje uplatom po ovom dokumentu.</div><br><div style='color: rgb(0, 0, 0); font-size: 12pt;'>Dokument se ne može koristiti za knjiženje u poslovnim knjigama.</div></p>`,
                );
            case '4':
                return setEditorValue(
                    `${MemorandumHeader()}<br><h1 class='ql-align-center'><div style='font-size: 18pt;'>Izjava o kašnjenju</div></h1><br><br><p class='ql-align-center'>Izjavljujemo da kasnimo sa podnošenjem dokumentacije za vlasnika preduzetničke radnje ${
                        data?.full_name
                    }, JMBG: ${
                        data?.user.data.jmbg
                    }, radi dužeg boravka u inostranstvu vlasnika kao jedinog potpisnika dokumentacije.</p><br><br><br><br><br><br><br><p>S poštovanjem,<br>________________<p><br>M.P.`,
                );
            case '5':
                return setEditorValue(
                    `${MemorandumHeader()}<br><h1 class='ql-align-center'><div style='font-size: 18pt;'>Odluka o službenom putovanju</div></h1><br><br><p class='ql-align-center'>Upućuje se ____________________ na službeni put u:</p><p class='ql-align-center'>Br. lične karte:</p><p class='ql-align-center'>Br. pasoša:</p><p class='ql-align-center'>Dan polaska:</p><p class='ql-align-center'>Dan povratka:</p><p class='ql-align-center'>____________________ putuje automobilom.</p><p class='ql-align-center'>Putni troškovi padaju na teret ${
                        data?.full_name
                    }</p><br><br><br><br><br><br><br><p>S poštovanjem,<br>________________<p><br>M.P.`,
                );
            case '6':
                return setEditorValue(
                    `${MemorandumHeader()}<br><br><p class='ql-align-center'>Na osnovu člana 9. Zakona o računovodstvu dana ______ godine preduzetnik ${
                        data?.full_name
                    } donosi sledeću</p><br><br><p class='ql-align-center'>ODLUKU</p><br><p class='ql-align-center'>O određivanju lica ovlašćenog za potpisivanje</p><br><br><p class='ql-align-center'>Ovom Odlukom određuje se lice ovlašćeno za sastavljanje, kontrolu verodostojnosti i za potpisivanje računovodstvenih isprava i to:</p><p class='ql-align-center'>${
                        data?.user.data.first_name + ' ' + data?.user.data.last_name
                    } vlasnik preduzetničke radnje ${
                        data?.full_name
                    }, ima neograničena ovlašćenja i potpisuje samostalno računovodstvene isprave.</p><p class='ql-align-center'>Identifikaciona oznaka ovlašćenog lica je: ${
                        data?.signature
                    }</p><br><br><br><br><br><br><br><p>Preduzetnik<br>________________ M.P.<p>`,
                );
            case '7':
                return setEditorValue(
                    `${MemorandumHeader()}<br><br><p class='ql-align-center'>POTVRDA O ZAPOSLENJU I VISINI PRIMANJA</p><br><br><br><p class='ql-align-justify'>Popunjava poslodavac:<br><br>Potvrđujem da je ${
                        data?.user.data.first_name + ' ' + data?.user.data.last_name
                    }, JMBG: ${
                        data?.user.data.jmbg ? data?.user.data.jmbg : '_______________________'
                    }, iz ________________________________  (adresa prebivališta), sa ličnom kartom broj ________________ izdatom od MUP ________________ po zanimanju ________________  zaposlen kod ${
                        data?.full_name
                    }, na neodređeno vreme počev od ________________ godine, na radnom mestu u preduzetničkoj agenciji. U momentu izdavanja ove potvrde ${
                        data?.user.data.first_name + ' ' + data?.user.data.last_name
                    } je ostvario ukupan radni staž u trajanju od (gg.mm.dd) ________________.</p><p class='ql-align-left'>Prosečna zarada zaposlenog u prethodna 3 meseca iznosi ________________ (unesi osnovicu sa poreskog rešenja) dinara bruto.
                    </p><br><br><br><p class='ql-align-center'>PODACI O PREDUZEĆU</p><br><br>
                        <p class='large'>Isplatilac: ${data?.full_name}</p>
                        <p class='ql-border'>Sedište isplatioca: ${data?.street_address} ${data?.street_number} ${
                        data?.city
                    }</p>
                        <p class='ql-border'>PIB: ${data?.pib}</p>
                        <p class='ql-border'>Matični broj: ${data?.identification_number}</p>
                        <p class='ql-border'>Šifra delatnosti isplatioca: ${data?.activityCode?.data?.code} ${
                        data?.activityCode?.data?.name
                    }</p>
                    <br><br><br><br><br><p><br>________________ <p><p><br>Potpis<br><p>`,
                );
            case '8':
                return setEditorValue(
                    `${MemorandumHeader()}<br><br><p class='ql-align-center'>UGOVOR O POZAJMICI</p><br><br><br><p class='ql-align-justify'>UGOVORNE STRANE:<br>
                    <p class='ql-align-left'>1. ${data?.full_name}, ${data?.street_address} ${data?.street_number} ${
                        data?.city
                    }, PIB: ${data?.pib}, MB: ${data?.identification_number}</p>
                    <p class='ql-align-left'>2. ${data?.user.data.first_name + ' ' + data?.user.data.last_name}, ${
                        data?.user.data.address
                            ? data?.user.data.address + ' ' + data?.user.data.city
                            : '________________________________ (adresa)'
                    }, JMBG: ${data?.user.data.jmbg ? data?.user.data.jmbg : '_______________________'}</p>
                    </p><br><br><br><p class='ql-align-center'>ČLAN 1</p><br>
                    <p class='ql-align-center'>Predmet ovog Ugovora je pozajmica koju ${
                        data?.user.data.first_name + ' ' + data?.user.data.last_name
                    } iz ${
                        data?.user.data.city ? data?.user.data.city : '_______________________ (mesto)'
                    } dana _______________(datum) u skladu sa zakonskim odredbama daje: ${data?.full_name}.</p>
                    </p><br><br><p class='ql-align-center'>ČLAN 2</p><br>
                    <p class='ql-align-center'>${
                        data?.user.data.first_name + ' ' + data?.user.data.last_name
                    } će svoju obavezu iz člana 1 ovog Ugovora ispuniti tako što će ${
                        data?.full_name
                    } uplatiti svoja raspoloživa sredstva u iznosu od<br>______________ dinara.</p>
                    </p><br><br><p class='ql-align-center'>ČLAN 3</p><br>
                    <p class='ql-align-center'>${data?.full_name} se obavezuje da kada se za to budu stekli uslovi ${
                        data?.user.data.first_name + ' ' + data?.user.data.last_name
                    } vrati Pozajmicu u iznosu navedenom u članu 2 ovog Ugovora.</p>
                    </p><br><br><p class='ql-align-center'>ČLAN 4</p><br>
                    <p class='ql-align-center'>Ugovor je sačinjen u 2 (dva) istovetna primerka od kojih po 1 (jedan) za svaku ugovornu stranu.</p>
                    <br><br><br><br><br><p class='ql-align-center'>UGOVORNE STRANE:</p><br><br>
                    <div class='ql-align-center'>_______________________${'&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'}_______________________</div>
                    <br>
                    <p class='ql-align-center'>${
                        data?.user.data.first_name + ' ' + data?.user.data.last_name
                    }${'&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'}${
                        data?.full_name
                    }<br><br></p>`,
                );
            default:
                return setEditorValue(MemorandumHeader());
        }
    };
    const getMemorandums = async (): Promise<void> => {
        setIsLoader(true);
        try {
            const res: ObjectKeys = await communication.getAllMemorandums('1', '10', 'any', '');
            useMemoStore.setState({ memoData: res.data });
            return Promise.resolve();
        } catch (error) {
            console.error('Error fetching memorandums:', error);
            return Promise.reject(error);
        } finally {
            setIsLoader(false);
        }
    };

    const handleChange = (value: any): void => {
        setRenderKey(renderKey + 1);
        setEditorValue(value);
    };

    const handleSave = async (): Promise<ObjectKeys['data']> => {
        try {
            setIsLoader(true);

            const data = {
                template: editorValue,
                status: MemorandumStatus.preparation,
                heading: memorandumName,
                memorandum_type: name,
            };

            const res = await communication.createMemorandum(data);

            setMemorandum(res.data.data);
            setIsLoader(false);
            navigate(-1);

            return res.data.data;
        } catch (error) {
            // Handle errors if needed
            console.error('Error in handleSave:', error);
            throw error; // Re-throw the error to be caught by the caller, if necessary
        }
    };

    const handlePrint = async (): Promise<void> => {
        const data = {
            template: editorValue,
            status: MemorandumStatus.preparation,
            heading: memorandumName,
            memorandum_type: name,
        };

        await communication
            .createMemorandum(data)
            .then(async (res: ObjectKeys) => {
                const memorandumId = res?.data?.data?.id;
                if (memorandumId) {
                    communication.downloadMemorandum(memorandumId, token).then((response: any) => {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', `${memorandumName}.pdf`);
                        document.body.appendChild(link);
                        link.click();
                    });
                    navigate(-1);
                } else {
                    console.error('Failed to retrieve memorandum ID');
                }
            })
            .catch((error: any) => {
                console.error('Error during API calls:', error);
            });
    };

    const handleSend = (): void => {
        setModalVisible(true);
    };

    // Modules object for setting up the Quill editor
    const modules = {
        toolbar: {
            container: '#toolbar',
            history: {
                delay: 0,
                maxStack: 100,
                userOnly: true,
            },
        },
    };

    return (
        <PageWrapper>
            {isLoader && <Loader />}
            {modalVisible && (
                <Sidebar close={() => setModalVisible(false)}>
                    <SendDocumentModal
                        close={() => setModalVisible(false)}
                        setFormData={setSendData}
                        formData={sendData}
                        t={t}
                        id={memorandum?.id}
                        success={() => {
                            getMemorandums()
                                .then(() => {
                                    navigate('/memorandums');
                                })
                                .catch((error) => {
                                    console.error('Error in success:', error);
                                });
                        }}
                        loading={() => setIsLoader(true)}
                        memorandum={true}
                        handleSaveMemorandum={handleSave}
                        modalVisible={modalVisible}
                        memoId={memorandum?.id}
                    />
                </Sidebar>
            )}

            <Header>
                <div className="title">
                    <div
                        className={'back-button'}
                        onClick={() => {
                            navigate(-1);
                        }}
                    >
                        <FontAwesomeIcon className={'icon'} icon={faAngleLeft} color={colors.gray} />
                    </div>
                    <h1>{t('navbar.pausal.memorandums').text}</h1>
                </div>
                <div className="headerButtons">
                    <Button
                        className={'action-button'}
                        onClick={async () => {
                            await handleSave();
                        }}
                        color={colors.green}
                        variant={ButtonVariant.solid}
                        size={'20px'}
                        height={'20px'}
                        icon={true}
                    >
                        <FontAwesomeIcon className="icon" color={colors.white} icon={faSave} />
                        {t('pages.editInvoice.save').text}
                    </Button>{' '}
                    <Button
                        className={'action-button'}
                        onClick={() => {
                            handlePrint();
                        }}
                        color={colors.purple}
                        variant={ButtonVariant.outlined}
                        size={'20px'}
                        height={'20px'}
                        icon={true}
                    >
                        <FontAwesomeIcon className="icon" color={colors.purple} icon={faPrint} />
                        {t('pages.editInvoice.print').text}
                    </Button>{' '}
                    <Button
                        className={'action-button'}
                        onClick={() => {
                            handleSend();
                        }}
                        color={colors.blue}
                        variant={ButtonVariant.solid}
                        size={'20px'}
                        height={'20px'}
                        icon={true}
                    >
                        <FontAwesomeIcon className="icon" color={colors.white} icon={faEnvelope} />
                        {t('pages.editInvoice.send').text}
                    </Button>
                </div>
            </Header>
            <Input
                type="text"
                label={t('pages.memos.name').text}
                className={'input'}
                value={memorandumName}
                onChange={(value: string) => setMemorandumName(value)}
            />
            <QuillToolbar quillRef={quillRef} />
            <ReactQuill
                className={'ql-editor'}
                ref={quillRef}
                theme="snow"
                value={editorValue}
                onChange={handleChange}
                placeholder={''}
                modules={modules}
                formats={formats}
            />
            <ButtonWrapper>
                <Button
                    className={'action-button'}
                    onClick={async () => {
                        await handleSave();
                    }}
                    color={colors.green}
                    variant={ButtonVariant.solid}
                    size={'20px'}
                    height={'20px'}
                    icon={true}
                >
                    <FontAwesomeIcon className="icon" color={colors.white} icon={faSave} />
                    {t('pages.editInvoice.save').text}
                </Button>{' '}
                <Button
                    className={'action-button'}
                    onClick={() => {
                        handlePrint();
                    }}
                    color={colors.purple}
                    variant={ButtonVariant.outlined}
                    size={'20px'}
                    height={'20px'}
                    icon={true}
                >
                    <FontAwesomeIcon className="icon" color={colors.purple} icon={faPrint} />
                    {t('pages.editInvoice.print').text}
                </Button>{' '}
                <Button
                    className={'action-button'}
                    onClick={() => {
                        handleSend();
                    }}
                    color={colors.blue}
                    variant={ButtonVariant.solid}
                    size={'20px'}
                    height={'20px'}
                    icon={true}
                >
                    <FontAwesomeIcon className="icon" color={colors.white} icon={faEnvelope} />
                    {t('pages.editInvoice.send').text}
                </Button>
            </ButtonWrapper>
        </PageWrapper>
    );
};
const PageWrapper = styled.div`
    height: 100%;
    width: 60.67vw;
    display: inline-block;
    vertical-align: top;
    @media screen and (max-width: ${MQ_BREAKPOINTS.laptop}) {
        width: 100%;
    }
    .back-button {
        width: 30px;
        height: 30px;
        margin: 0 10px 0 0;
        vertical-align: top;
        background: #fff;
        border: 1px solid #e7e7e7;
        border-radius: 3px;
        text-align: center;
        display: block;
    }
    .action-button {
        width: auto;
        padding: 15px 20px;
        margin-left: 20px;
        @media screen and (max-width: ${MQ_BREAKPOINTS.mobileL}) {
            width: 100px;
            margin-left: 10px;
            margin-bottom: 3px;
        }
    }
    .quill {
        width: 60.67vw;
    }

    .ql-container {
        height: 100vh;
    }

    .ql-editor {
        background-color: var(--white);
        padding: 0px;
        //display: grid;
        height: 100%;
        @media screen and (max-width: ${MQ_BREAKPOINTS.laptop}) {
            width: 100%;
        }
        .ql-container {
            font-family: 'Open Sans', sans-serif;
            padding: 12px 15px;
        }
        .span {
            font-size: 12pt;
            line-height: 2;
        }
        img {
            height: auto;
            width: 6.7067vw;
            margin-left: auto !important;
            margin-right: auto !important;
            display: block;
        }
        h1 {
            line-height: 1.3em;
            text-align: center;
            color: #000;
            font-weight: 700;
            margin: 0.67em 0;
        }
        p {
            font-size: 1.38vw;
            line-height: 1.785vw;
            color: #000;
        }
        hr {
            height: 0px;
            margin-top: 10px;
            margin-bottom: 10px;
        }
    }
    .input {
        input {
            background-color: var(--white);
            &:hover {
                border-bottom: 1px solid var(--border-color);
            }
            &:focus {
                border-bottom: 1px solid var(--border-color);
            }
        }
    }
    .spanblock {
        background-color: #f8f8f8;
        border: 1px solid #ccc;
        line-height: 19px;
        padding: 6px 10px;
        border-radius: 3px;
        margin: 15px 0;
    }
`;

const Header = styled.div`
    padding-bottom: 20px;
    position: relative;
    display: flex;
    align-items: center;
    h1 {
        font-size: 36px;
        display: inline-block;
    }
    .header-right {
        float: right;
        margin: 0 0 0 35px;
    }
    .header-options-button {
        display: inline-block;
    }
    .buttons {
        color: var(--purple);
        display: inline-block;
        &:first-child {
            margin-right: 10px;
        }
    }
    .headerButtons {
        display: flex;
        @media screen and (max-width: ${MQ_BREAKPOINTS.mobileL}) {
            margin-top: 10px;
        }
    }
    .title {
        display: contents;
        align-items: end;
        @media screen and (max-width: ${MQ_BREAKPOINTS.mobileL}) {
            display: flex;
        }
    }
    @media screen and (max-width: ${MQ_BREAKPOINTS.mobileL}) {
        display: block;
    }
`;
const ButtonWrapper = styled.div`
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
`;
export default NewMemorandum;
