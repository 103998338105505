import { FunctionComponent, useEffect, useState } from 'react';
import { nanoid } from 'nanoid';
import styled from 'styled-components';
import { ObjectKeys } from '../../../../types/objectKeys';
import moment from 'moment';
import { formatNumber, formatTwoDecimals } from '../../../../functions/format';
import logo from '../../../../assets/logo.png';
import { getUnitByValue } from '../measurementUnits';
import countTotal from '../countTotal';
import { MQ_BREAKPOINTS } from '../../../../constants/breakpoints';
import useProfileStore from '../../../../store/profile';

interface Props {
    invoice: ObjectKeys;
    agency: ObjectKeys;
    title: string;
    type?: string;
    note?: string;
    qrCode?: string;
    advanceTotal?: string;
}

const PreviewInvoiceModal: FunctionComponent<Props> = ({
    invoice,
    agency,
    title,
    type,
    note,
    qrCode,
    advanceTotal,
}) => {
    const appUrl = process.env.REACT_APP_URL;

    const logoUrl = agency.logo ? `${appUrl}/${agency.logo}` : null;
    const [renderKey, setRenderKey] = useState<number>(0);

    const { profile } = useProfileStore();

    const has_logo_on_invoice = profile.has_logo;

    const [totalWithoutRabat, setTotalWithoutRabat] = useState<number | null>(null);
    const [rabat, setRabat] = useState<number | null>(null);

    const isPrepaid = invoice && invoice.is_prepaid;

    const calculateTotalAmountWithoutDiscounts = (items: ObjectKeys): void => {
        if (items && items.length > 0) {
            const totalResult = items.reduce(
                (accumulator: ObjectKeys, service: ObjectKeys) => {
                    const { pivot } = service;
                    if (pivot) {
                        const quantity = pivot.quantity ? Number(pivot.quantity.replace(',', '.')) : 0;
                        const pricePerUnit = pivot.price_per_unit ? Number(pivot.price_per_unit.replace(',', '.')) : 0;
                        const discount = pivot.discount ? Number(pivot.discount) : 0;
                        const amountWithoutDiscount = quantity * pricePerUnit;
                        const discountAmount = quantity * pricePerUnit * (discount / 100);

                        accumulator.totalWithoutDiscount += amountWithoutDiscount;
                        accumulator.totalDiscount += discountAmount;
                    }
                    return accumulator;
                },
                { totalWithoutDiscount: 0, totalDiscount: 0 },
            );

            setTotalWithoutRabat(totalResult.totalWithoutDiscount);
            setRabat(-totalResult.totalDiscount);
        } else {
            setTotalWithoutRabat(0);
            setRabat(0);
        }
    };

    const calculateTotalPrepaidNegative = (item: ObjectKeys): string => {
        let total = 0;
        if (item.prepaid_invoices?.length > 0) {
            item.prepaid_invoices.forEach((element: ObjectKeys) => {
                total += Number(element.pivot.value);
            });
        } else if (item.prepaid_invoice_ids?.length > 0) {
            item.prepaid_invoice_ids.forEach((element: ObjectKeys) => {
                total += Number(element.value);
            });
        }
        if (total !== 0) {
            total = -total; // Convert total to negative
            return formatTwoDecimals(total.toString());
        }
        return '';
    };

    useEffect(() => {
        calculateTotalAmountWithoutDiscounts(invoice.services);
    }, [invoice.value]);

    useEffect(() => {
        setRenderKey(renderKey + 1);
    }, []);

    const foreignInvoiceText =
        type === 'foreign'
            ? {
                  title:
                      title === 'invoice' ? 'Invoice / ' : title === 'proforma' ? 'Proforma / ' : 'Advance Invoice / ',
                  dated: 'Invoice date / ',
                  valueDate: 'Trading date / ',
                  tradingPlace: 'Trading place / ',
                  invoiceDetails: 'Invoice details / ',
                  from: 'From / ',
                  vatNo: 'VAT/EIB / ',
                  jbkjs: 'Public company ID / ',
                  idNo: 'ID No / ',
                  personalIdNo: 'Personal ID No / ',
                  billTo: 'Bill to / ',
                  address: 'Address / ',
                  city: 'City / ',
                  country: 'Country / ',
                  item: 'TYPE OF SERVICE',
                  unit: 'Unit',
                  unitAdvance: 'Advance / ',
                  quantity: 'Quantity',
                  price: 'Price',
                  rabat: 'DISCOUNT ',
                  total: 'Total',
                  amountDue: 'TOTAL ',
                  prepaidTotal: 'ADVANCE / ',
                  amountDuePayment: 'TOTAL FOR PAYMENT/ ',
                  note: 'COMMENT / OPIS USLUGE',
                  identificationNum: 'Identification number / ',
                  notVatSystem: 'Not in the VAT system. / ',
                  vatCalcProforma:
                      'VAT not calculated on the proforma according to article 33 of Law on value added tax. / ',
                  vatCalcInvoice:
                      'VAT not calculated on the invoice according to article 33 of Law on value added tax. / ',
                  vatCalcAInvoice:
                      'VAT not calculated on the invoice according to article 33 of Law on value added tax. / ',
                  docValid: 'Document is valid without stamp and signature. / ',
                  place: 'Place / ',
                  putCallNoInvoice: 'When making the payment, please provide a reference number xx/xxxx / ',
                  bankAccount: 'BANK ACCOUNT / ',
                  noteTaxFree: 'NOTE ON TAX EXEMPTION / ',
                  invoiceMadeby: 'Invoice made by ',
                  proformaMadeby: 'Proforma made by ',
                  advanceMadeby: 'Advance invoice made by ',
              }
            : {};

    return (
        <Container>
            <LeftColumn>
                <div className="container">
                    <div>
                        <div className="top-logo">
                            {logoUrl ? (
                                <AgencyLogo>
                                    <img src={`${logoUrl}?renderKey=${renderKey}`} alt="Agency logo" />
                                </AgencyLogo>
                            ) : (
                                <></>
                            )}
                        </div>
                        <div className="thick-line"></div>
                        {agency && (
                            <Agency>
                                <div className="client">
                                    <h4 className="client-name">
                                        {invoice?.client?.data.company_name ? invoice?.client?.data.company_name : ''}
                                    </h4>
                                    <p>
                                        {foreignInvoiceText?.address}
                                        {'Adresa:' +
                                            '\xa0' +
                                            (invoice?.client?.data.address ? invoice?.client?.data.address : '/')}
                                    </p>
                                    <p>
                                        {foreignInvoiceText?.city}
                                        {'Grad:' +
                                            '\xa0' +
                                            (invoice?.client?.data.city ? invoice?.client?.data.city : '/')}
                                    </p>
                                    {type === 'foreign' ? (
                                        <>
                                            <p>
                                                {foreignInvoiceText?.country}
                                                {'Država:'}
                                                {'\xa0'}
                                                {invoice?.client?.data.country ? invoice?.client?.data.country : '/'}
                                            </p>
                                            <p>
                                                {foreignInvoiceText?.vatNo}
                                                {'PIB:'}
                                                {'\xa0'}
                                                {invoice?.client?.data.pib ? invoice?.client?.data.pib : '/'}
                                            </p>
                                            <p>
                                                {foreignInvoiceText?.idNo}
                                                {'MB:'}
                                                {'\xa0'}
                                                {invoice?.client?.data.identification_number
                                                    ? invoice?.client?.data.identification_number
                                                    : '/'}
                                            </p>
                                        </>
                                    ) : (
                                        <>
                                            {invoice?.client?.data.type === 'civil' && (
                                                <p>
                                                    {foreignInvoiceText?.personalIdNo}
                                                    {'JMBG: '}
                                                    {'\xa0'}
                                                    {invoice?.client?.data.jmbg ? invoice?.client?.data.jmbg : '/'}
                                                </p>
                                            )}
                                            {invoice?.client?.data.type === 'legal' ||
                                            invoice?.client?.data.type === 'public' ? (
                                                <>
                                                    <p>
                                                        {foreignInvoiceText?.vatNo}
                                                        {'PIB:'}
                                                        {'\xa0'}
                                                        {invoice?.client?.data.pib ? invoice?.client?.data.pib : '/'}
                                                    </p>
                                                    <p>
                                                        {foreignInvoiceText?.idNo}
                                                        {'MB:'}
                                                        {'\xa0'}
                                                        {invoice?.client?.data.identification_number
                                                            ? invoice?.client?.data.identification_number
                                                            : '/'}
                                                    </p>
                                                </>
                                            ) : null}
                                            {invoice?.client?.data.type === 'public' && (
                                                <p>
                                                    {foreignInvoiceText?.jbkjs}
                                                    {'JBKJS: '}
                                                    {'\xa0'}
                                                    {invoice?.client?.data.public_company_id
                                                        ? invoice?.client?.data.public_company_id
                                                        : '/'}
                                                </p>
                                            )}
                                        </>
                                    )}
                                </div>
                            </Agency>
                        )}
                        <div className="thick-line"></div>
                        <Header>
                            <InvoiceDetails>
                                <h4 className="client-name">{foreignInvoiceText?.invoiceDetails}Detalji fakture</h4>
                                <Row
                                    className={`date ${type === 'foreign' || title !== 'invoice' ? 'flex-column' : ''}`}
                                >
                                    <p>
                                        {foreignInvoiceText?.dated}
                                        {title === 'invoice'
                                            ? 'Datum fakture:'
                                            : title === 'proforma'
                                            ? 'Datum profakture:'
                                            : 'Datum avansne fakture:'}{' '}
                                    </p>
                                    <p>
                                        {invoice.invoice_date
                                            ? moment(invoice.invoice_date).format('DD.MM.YYYY.')
                                            : invoice.quotation_date
                                            ? moment(invoice.quotation_date).format('DD.MM.YYYY.')
                                            : moment(new Date()).format('DD.MM.YYYY.')}
                                    </p>
                                </Row>
                                <Row
                                    className={`date ${type === 'foreign' || title !== 'invoice' ? 'flex-column' : ''}`}
                                >
                                    <p>
                                        {foreignInvoiceText?.valueDate}
                                        {'Datum prometa:'}
                                    </p>
                                    <p>
                                        {invoice.trading_date
                                            ? moment(invoice.trading_date).format('DD.MM.YYYY.')
                                            : moment(new Date()).format('DD.MM.YYYY.')}
                                    </p>
                                </Row>
                                <Row
                                    className={`date ${type === 'foreign' || title !== 'invoice' ? 'flex-column' : ''}`}
                                >
                                    <p>
                                        {foreignInvoiceText?.tradingPlace}
                                        {'Mesto prometa:'}
                                    </p>
                                    <p>{invoice.trading_place}</p>
                                </Row>
                            </InvoiceDetails>
                        </Header>
                        <div className="thick-line"></div>
                        {agency && (
                            <Client>
                                <div>
                                    <h4 className="client-name">
                                        {foreignInvoiceText?.from}
                                        {'Od:'}
                                    </h4>
                                    <p>{agency.name}</p>
                                    <p>{agency.full_name}</p>
                                    <p>{agency.street_address + '\xa0' + agency.street_number}</p>
                                    <p>{agency.city}</p>
                                    <p>
                                        {foreignInvoiceText?.vatNo}
                                        {'PIB:' + '\xa0' + agency.pib}
                                    </p>
                                    <p>
                                        {foreignInvoiceText?.idNo}
                                        {'MB:' + '\xa0' + agency.identification_number}
                                    </p>
                                    {type === 'foreign' ? (
                                        <>
                                            <p>
                                                {'IBAN'}: {invoice.iban ? invoice.iban : '/'}
                                            </p>
                                            <p>
                                                {'SWIFT'}: {invoice.swift ? invoice.swift : '/'}
                                            </p>
                                        </>
                                    ) : (
                                        <p>
                                            {'ŽIRO RAČUN:'}
                                            <br></br>
                                            {invoice?.bank_account ? invoice?.bank_account : agency.first_bank_account}
                                        </p>
                                    )}
                                    <p>{'E-mail:'}</p>
                                    <p>{agency?.agency_email ? agency?.agency_email : '/'}</p>
                                </div>
                            </Client>
                        )}
                    </div>
                    <div>
                        <div className="thick-line"></div>
                        <div className="amount-left">
                            <h4>{foreignInvoiceText?.total ? foreignInvoiceText.total + ' / ' : ''}Ukupan iznos</h4>
                            <span className="amount-total">
                                {invoice.currency}:
                                {title !== 'invoice' ? (
                                    <span>
                                        {invoice.value ? (
                                            <p className="margin-left">
                                                {formatTwoDecimals(invoice?.value?.toString())}
                                            </p>
                                        ) : (
                                            <p className="margin-left">
                                                {formatNumber(countTotal(invoice.services), 2, 3, '.', ',')}
                                            </p>
                                        )}
                                    </span>
                                ) : (
                                    <span>
                                        {advanceTotal ? (
                                            <p className="margin-left">{formatTwoDecimals(advanceTotal.toString())}</p>
                                        ) : invoice?.total_for_payment ? (
                                            <p className="margin-left">
                                                {formatTwoDecimals(invoice.total_for_payment.toString())}
                                            </p>
                                        ) : (
                                            <p className="margin-left">
                                                {formatNumber(countTotal(invoice.services), 2, 3, '.', ',')}
                                            </p>
                                        )}
                                    </span>
                                )}
                            </span>
                        </div>
                    </div>
                </div>
            </LeftColumn>
            <RightColumn>
                <div className="upper-line">
                    <Row className="inv-no">
                        <h4 className="title1">
                            {foreignInvoiceText?.title}
                            {title === 'invoice'
                                ? 'Faktura'
                                : title === 'proforma'
                                ? 'Profaktura'
                                : 'Avansna faktura'}{' '}
                        </h4>
                        <h4 className="title">
                            {invoice.invoice_number
                                ? invoice.invoice_number
                                : invoice.quotation_number
                                ? invoice.quotation_number
                                : 'xx/xxx'}
                        </h4>
                    </Row>
                    <Row className="services1">
                        {type === 'domestic' ? (
                            <>
                                <h4 className="type">{'Vrsta usluge'}</h4>
                                <h4 className="unit">{'Jed. mere'}</h4>
                                <h4 className="price">{'Količina'}</h4>
                                <h4 className="price">{'Cena'}</h4>
                                {!isPrepaid ? <h4 className="price">{'Rabat %'}</h4> : null}
                                <h4 className="total">{'Ukupno'}</h4>
                            </>
                        ) : (
                            <>
                                <div className="type">
                                    <h4>{foreignInvoiceText?.item}</h4>
                                    <span>( {'Vrsta usluge'} )</span>
                                </div>
                                <div className="unit">
                                    <h4>{foreignInvoiceText?.unit}</h4>
                                    <span>( {'Jed. mere'} )</span>
                                </div>
                                <div className="price">
                                    <h4>{foreignInvoiceText?.quantity}</h4>
                                    <span>( {'Količina'} )</span>
                                </div>
                                <div className="price">
                                    <h4>{foreignInvoiceText?.price}</h4>
                                    <span>( {'Cena'} )</span>
                                </div>
                                {!isPrepaid ? (
                                    <div className="price">
                                        <h4>{foreignInvoiceText?.rabat}</h4>
                                        <span>( {'Rabat %'} )</span>
                                    </div>
                                ) : null}
                                <div className="total">
                                    <h4 style={{ marginRight: '8px' }}>{foreignInvoiceText?.total}</h4>
                                    <span>( {'Ukupno'} )</span>
                                </div>
                            </>
                        )}
                    </Row>
                    {invoice?.services ? (
                        <>
                            {invoice?.services?.map((service: ObjectKeys) => (
                                <div key={nanoid()}>
                                    <Row className="services">
                                        <p className="type">{service.name}</p>
                                        <p className="unit">
                                            {getUnitByValue(service.pivot.measurement_unit, type === 'foreign', true)}
                                        </p>
                                        <p className="price">{formatTwoDecimals(service?.pivot?.quantity)}</p>
                                        <p className="price">{formatTwoDecimals(service?.pivot?.price_per_unit)}</p>
                                        {!isPrepaid && <p className="price">{service?.pivot?.discount}</p>}
                                        <p className="total">
                                            {formatNumber(service?.pivot?.total_price, 2, 3, '.', ',')}
                                        </p>
                                    </Row>
                                </div>
                            ))}
                            {invoice?.prepaid_invoice_ids
                                ? invoice.prepaid_invoice_ids.map((item: ObjectKeys, index: number) => (
                                      <Row className="services" key={index}>
                                          <p className="type">{item.invoice_number}</p>
                                          <p className="unit">{foreignInvoiceText?.unitAdvance}Avans</p>
                                          <p className="price">1,00</p>
                                          <p className="price">-{formatTwoDecimals(item.value.toString())}</p>
                                          {!isPrepaid && <p className="price"> </p>}
                                          <p className="total">-{formatTwoDecimals(item.value.toString())}</p>
                                      </Row>
                                  ))
                                : null}
                            {invoice?.prepaid_invoices
                                ? invoice.prepaid_invoices.map((item: ObjectKeys, index: number) => (
                                      <Row className="services" key={index}>
                                          <p className="type">
                                              A {item.invoice_number}/{item.invoice_date.split('-')[0]}
                                          </p>
                                          <p className="unit">{foreignInvoiceText?.unitAdvance}Avans</p>
                                          <p className="price">1,00</p>
                                          <p className="price">-{formatTwoDecimals(item.pivot.value.toString())}</p>
                                          {!isPrepaid && <p className="price"> </p>}
                                          <p className="total">-{formatTwoDecimals(item.pivot.value.toString())}</p>
                                      </Row>
                                  ))
                                : null}
                            {totalWithoutRabat !== null && !isPrepaid ? (
                                <Total>
                                    <h4>
                                        {foreignInvoiceText?.amountDue}
                                        {'UKUPNO '} ({invoice.currency})
                                    </h4>
                                    <p className="right">{formatTwoDecimals(totalWithoutRabat.toString())}</p>
                                </Total>
                            ) : null}
                            {rabat !== null && !isPrepaid ? (
                                <Total>
                                    <h4>
                                        {foreignInvoiceText?.rabat} {foreignInvoiceText?.rabat ? ' / ' : ''}
                                        {'RABAT '} ({invoice.currency})
                                    </h4>
                                    <p className="right">{formatTwoDecimals(rabat.toString())}</p>
                                </Total>
                            ) : null}
                            {invoice?.prepaid_invoices?.length === 0 || invoice?.prepaid_invoice_ids?.length === 0 ? (
                                <Total>
                                    <h4>
                                        {foreignInvoiceText?.amountDue}
                                        {'UKUPNO ZA UPLATU'} ({invoice.currency})
                                    </h4>
                                    <p className="right">
                                        {formatNumber(countTotal(invoice.services), 2, 3, '.', ',')}
                                    </p>
                                </Total>
                            ) : null}
                            <Total>
                                <h4>
                                    {foreignInvoiceText?.amountDue}
                                    {'UKUPNO '} ({invoice.currency})
                                </h4>
                                <p className="right">{formatNumber(countTotal(invoice.services), 2, 3, '.', ',')}</p>
                            </Total>

                            {invoice?.prepaid_invoices?.length > 0 || invoice?.prepaid_invoice_ids?.length > 0 ? (
                                <Total>
                                    <h4>
                                        {foreignInvoiceText?.prepaidTotal}
                                        {'AVANSI'} ({invoice.currency})
                                    </h4>
                                    <p className="right">{calculateTotalPrepaidNegative(invoice)}</p>
                                </Total>
                            ) : null}
                            {invoice?.prepaid_invoices?.length > 0 || invoice?.prepaid_invoice_ids?.length > 0 ? (
                                <Total>
                                    <h4>
                                        {foreignInvoiceText?.amountDuePayment}
                                        {'UKUPNO ZA UPLATU'} ({invoice.currency})
                                    </h4>
                                    {advanceTotal !== null && advanceTotal !== undefined ? (
                                        <p className="right">{formatTwoDecimals(advanceTotal.toString())}</p>
                                    ) : (
                                        <p className="right">
                                            {formatTwoDecimals(invoice.total_for_payment.toString())}
                                        </p>
                                    )}
                                </Total>
                            ) : null}
                            {/* only for proformas */}
                            {title === 'proforma' ? (
                                <Total>
                                    <h4>
                                        {foreignInvoiceText?.amountDue}
                                        {'UKUPNO ZA UPLATU'} ({invoice.currency})
                                    </h4>
                                    <p className="right">
                                        {formatNumber(countTotal(invoice.services), 2, 3, '.', ',')}
                                    </p>
                                </Total>
                            ) : null}
                        </>
                    ) : (
                        <></>
                    )}
                </div>

                <div className="bottom-line">
                    <Row>
                        <Comments>
                            <h4>
                                {foreignInvoiceText?.note}
                                {!foreignInvoiceText?.note && 'Komentar / opis usluge:'}
                            </h4>
                            <p
                                dangerouslySetInnerHTML={{
                                    __html: note
                                        ? note.replace(/<b>/g, '&lt;b&gt;').replace(/<\/b>/g, '&lt;/b&gt;')
                                        : 'Rok za plaćanje je 15 dana',
                                }}
                                style={{ whiteSpace: 'pre-wrap' }}
                            ></p>
                            <p>
                                {foreignInvoiceText?.putCallNoInvoice}
                                {title === 'proforma'
                                    ? 'Pri plaćanju profakture navedite poziv na broj xx/xxxx'
                                    : 'Pri plaćanju fakture navedite poziv na broj xx/xxxx'}
                            </p>
                            <p>
                                {foreignInvoiceText?.identificationNum}
                                {'Identifikacioni broj:  '} <br></br>
                                {agency.signature}
                            </p>
                            <p>
                                {foreignInvoiceText?.docValid}
                                {title === 'invoice'
                                    ? 'Faktura je važeća bez pečata i potpisa.'
                                    : title === 'proforma'
                                    ? 'Profaktura je važeća bez pečata i potpisa.'
                                    : 'Avansna faktura je važeća bez pečata i potpisa.'}
                            </p>
                            {type === 'foreign' && (
                                <p>
                                    {'Place of issue / Mesto izdavanja: '}
                                    {agency.city}
                                </p>
                            )}
                            {type !== 'foreign' && (
                                <p>
                                    {'Mesto izdavanja: '}
                                    {agency.city}
                                </p>
                            )}
                            <br></br>
                            <h4>
                                {foreignInvoiceText?.noteTaxFree}
                                {'Napomena o poreskom oslobođenju:'}
                            </h4>
                            <p>
                                {foreignInvoiceText?.notVatSystem}
                                {'Poreski obveznik nije u sistemu PDV-a.'}
                            </p>
                            <p>
                                {title === 'invoice'
                                    ? `${
                                          foreignInvoiceText?.vatCalcInvoice ? foreignInvoiceText?.vatCalcInvoice : ''
                                      }PDV nije obračunat na fakturi u skladu sa članom 33. Zakona o porezu na dodatu vrednost.`
                                    : title === 'proforma'
                                    ? `${
                                          foreignInvoiceText?.vatCalcProforma ? foreignInvoiceText?.vatCalcProforma : ''
                                      }PDV nije obračunat na profakturi u skladu sa članom 33. Zakona o porezu na dodatu vrednost.`
                                    : `${
                                          foreignInvoiceText?.vatCalcAInvoice ? foreignInvoiceText?.vatCalcAInvoice : ''
                                      }PDV nije obračunat na avansnoj fakturi u skladu sa članom 33. Zakona o porezu na dodatu vrednost.`}
                            </p>
                        </Comments>
                        {qrCode && (
                            <div className="qr_code">
                                <img
                                    width={'150px'}
                                    height={'150px'}
                                    data-v-f036a56e=""
                                    data-v-251216d5=""
                                    src={`data:image/jpeg;base64,${qrCode}`}
                                    data-v-52b191c5=""
                                    key={qrCode}
                                ></img>
                            </div>
                        )}
                    </Row>
                    <Footer>
                        {type === 'foreign' ? <p>Page 1 of 1</p> : <p>Strana 1 od 1</p>}
                        {has_logo_on_invoice ? (
                            <div className="logo">
                                <p>
                                    {title === 'invoice'
                                        ? foreignInvoiceText?.invoiceMadeby
                                            ? foreignInvoiceText?.invoiceMadeby
                                            : 'Faktura kreirana preko '
                                        : title === 'proforma'
                                        ? foreignInvoiceText?.proformaMadeby
                                            ? foreignInvoiceText?.proformaMadeby
                                            : 'Profaktura kreirana preko '
                                        : foreignInvoiceText?.advanceMadeby
                                        ? foreignInvoiceText?.advanceMadeby
                                        : 'Avansna faktura kreirana preko '}
                                    <a href="http://www.pausal.rs" target="_blank" rel="noreferrer">
                                        pausal.rs
                                    </a>
                                </p>
                                <img src={logo} height={'30px'} />
                            </div>
                        ) : null}
                    </Footer>
                </div>
            </RightColumn>
        </Container>
    );
};

export default PreviewInvoiceModal;

const Container = styled.div`
    min-width: 750px;
    display: flex;
    // padding: 40px 30px 20px 140px;
    color: black !important;
    p {
        font-size: 12px;
        // word-break: break-all;
    }
    h4 {
        font-weight: 600;
        font-size: 12px;
        &.title {
            margin-top: 15px;
            font-size: 20px;
            font-weight: 700;
        }
        &.agency-name {
            overflow-wrap: anywhere;
            font-size: 17px;
        }
        &.client-name {
            font-size: 17px;
            overflow-wrap: anywhere;
        }
    }
    @media only screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
        padding: 30px;
    }
    @media only screen and (max-width: ${MQ_BREAKPOINTS.mobileL}) {
        padding: 10px;
        margin-bottom: 100px;
    }
    .close {
        position: absolute;
        top: 10px;
        right: 20px;
        font-size: 20px;
        cursor: pointer;
        @media only screen and (max-width: ${MQ_BREAKPOINTS.mobileL}) {
            top: 5px;
            right: 5px;
        }
    }
`;
const LeftColumn = styled.div`
    background-color: black;
    color: white;
    padding: 20px;
    flex: 30%;
    .container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 98%;
    }
    .top-logo {
        position: static;
        width: 170px;
        height: 150px;
        padding: 0 10px;
        img {
            width: 170px;
            height: 150px;
        }
        @media only screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
            position: static;
            padding: 0;
            margin-bottom: 10px;
        }
        @media only screen and (max-width: ${MQ_BREAKPOINTS.mobileL}) {
            text-align: center;
        }
    }
    .thick-line {
        margin-top: 20px;
        height: 5px;
        background-color: #8c52ff;
        width: 60%;
    }
    .thick-line2 {
        height: 5px;
        background-color: #8c52ff;
        width: 15%;
        margin-bottom: 10px;
    }
    empty-space {
        height: 400px !important;
    }
    .amount-left {
        // bottom: 20px;
        // position: absolute;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 20px;
        font-size: 20px;
        font-weight: 700;
        justify-content: space-between;
        h4 {
            width: 100%;
            font-size: 18px;
            font-weight: 500;
            margin-bottom: 10px;
        }
        p {
            font-size: 18px;
            font-weight: 500;
            text-align: center;
            border: none;
            display: flex;
            align-items: center;
            justify-content: right; /* Optional, for horizontal centering */
            span {
                margin-left: 10px;
            }
        }
        .amount-total {
            display: flex;
            font-size: 18px;
            font-weight: 500;
            align-items: center;
            .margin-left {
                margin-left: 10px;
            }
        }
    }
`;

const RightColumn = styled.div`
    padding: 10px;
    flex: 70%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .inv-no {
        margin-top: 25px;
        margin-bottom: 25px;
        display: flex;
        flex-direction: column;
        .title1 {
            font-size: 22px;
            font-weight: 700;
            margin: 0;
            text-align: center;
            color: #8c52ff;
        }
        .title {
            font-size: 18px;
            font-weight: 700;
            margin: 0;
            text-align: center;
        }
    }
    .bottom-line {
    }
    .upper-line {
        height: auto;
        min-height: 690px;
    }
`;
const AgencyLogo = styled.div`
    position: apsolute;
    left: 0;
    padding: 0 10px;
    img {
        width: 110px;
    }
    @media only screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
        position: static;
        padding: 0;
        margin-bottom: 10px;
    }
    @media only screen and (max-width: ${MQ_BREAKPOINTS.mobileL}) {
        text-align: center;
    }
`;

const Header = styled.div`
    width: 100%;
    display: flex;
    padding-bottom: 20px;
    border-collapse: separate;
    margin-top: 10px;

    .date {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between; /* Add this line */
    }
    .date.flex-column {
        flex-direction: column;
    }
    .date p {
        line-height: 1.3;
    }
`;
const InvoiceDetails = styled.div`
    width: 100%;
    overflow-wrap: anywhere;
    display: flex;
    flex-direction: column;
    gap: 5px;
    &.right {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin-top: 10px;
        p {
            line-height: 1.2;
        }
    }
    &.services {
        text-align: end;
        width: 100%;
        padding-top: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid black;
        h4 {
            text-transform: uppercase;
            line-height: 1.3;
            @media only screen and (max-width: ${MQ_BREAKPOINTS.mobileL}) {
                font-size: 10px;
            }
        }
        span {
            text-transform: uppercase;
            font-size: 10px;
        }
    }
    .type {
        width: 30%;
        text-align: start;
        line-height: 0.8;
        text-align: center;
    }
    .price {
        width: 15%;
        line-height: 0.8;
        text-align: center;
    }
    .total {
        width: 25%;
        line-height: 0.8;
    }
    .qr_code {
        margin-top: 50px;
    }
`;
const Row = styled.div`
    display: flex;
    gap: 5px;
    &.right {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin-top: 10px;
        p {
            line-height: 1.2;
        }
    }
    &.services1 {
        text-align: end;
        background-color: var(--light-gray);
        width: 100%;
        padding-top: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid black;
        h4 {
            text-transform: uppercase;
            line-height: 1.3;
            @media only screen and (max-width: ${MQ_BREAKPOINTS.mobileL}) {
                font-size: 10px;
            }
        }
        span {
            text-transform: uppercase;
            font-size: 10px;
        }
    }
    &.services {
        text-align: end;
        width: 100%;
        padding-top: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid black;
        h4 {
            text-transform: uppercase;
            line-height: 1.3;
            @media only screen and (max-width: ${MQ_BREAKPOINTS.mobileL}) {
                font-size: 10px;
            }
        }
        span {
            text-transform: uppercase;
            font-size: 10px;
        }
    }
    .type {
        width: 30%;
        text-align: start;
        line-height: 0.8;
        text-align: start;
    }
    .price {
        width: 15%;
        line-height: 0.8;
        text-align: right;
    }
    .unit {
        width: 15%;
        line-height: 0.8;
        text-align: left;
    }
    .total {
        width: 25%;
        line-height: 0.8;
        font-weight: 700;
    }
    .qr_code {
        margin-top: 50px;
    }
`;
const Agency = styled.div`
    font-size: 12px;
    display: flex;
    gap: 5px;
    padding: 10px 0;
    h4 {
        margin-bottom: 15px;
    }
    p {
        margin-bottom: 8px;
        line-height: 1;
    }
`;
const Client = styled.div`
    font-size: 12px;
    display: flex;
    gap: 5px;
    height: 450px;
    padding: 10px 0;
    h4 {
        margin-bottom: 15px;
    }
    p {
        margin-bottom: 8px;
        line-height: 1;
    }
`;
const Total = styled.div`
    width: 100%;
    height: 35px;
    font-weight: 700;
    display: flex;
    border-bottom: 1px solid var(--black);
    h4 {
        width: 60%;
        display: flex;
        align-items: center;
        font-weight: bold;
    }
    p {
        width: 40%;
        font-size: 20px;
        font-weight: 700;
        text-align: center;
        border: none;
        display: flex;
        align-items: center;
        justify-content: right; /* Optional, for horizontal centering */
        color: black !important;
    }
`;
const Comments = styled.div`
    padding-top: 20px;
    h4 {
        font-size: 12px;
        margin-bottom: 10px;
        text-transform: uppercase;
    }
    p {
        margin-top: 5px;
        line-height: 1.3;
        word-break: unset;
    }
`;
const Footer = styled.div`
    border-top: 1px solid var(--black);
    width: 95%;
    padding-top: 20px;
    font-size: 12px;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media only screen and (max-width: ${MQ_BREAKPOINTS.mobileL}) {
        flex-direction: column;
        img {
            margin-top: 10px;
            width: auto;
        }
    }
    .logo {
        display: contents;
    }
`;
