import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import Loader from '../../../components/loader';
import useLanguageStore from '../../../store/language';
import useTranslations from '../../../hooks/useTranslation';
import { MQ_BREAKPOINTS } from '../../../constants/breakpoints';

interface PeriodFiltersProps {
    setChoosenDate: Dispatch<SetStateAction<number>>;
}

const PeriodFilters: React.FC<PeriodFiltersProps> = ({ setChoosenDate }) => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    const [selectedPeriod, setSelectedPeriod] = useState<string>('1 mesec');
    const [startDate, setStartDate] = useState<string>(moment().subtract(30, 'days').format('YYYY-MM-DD'));
    const [loader, setLoader] = useState<boolean>(false);

    const handlePeriodClick = (period: string): void => {
        setSelectedPeriod(period);
        let daysBack = 0;
        switch (period) {
            case '1 mesec':
                daysBack = 30;
                break;
            case '3 meseca':
                daysBack = 90;
                break;
            case '6 meseci':
                daysBack = 180;
                break;
            case '12 meseci':
                daysBack = 365;
                break;
            default:
                daysBack = 30;
        }
        const newStartDate = moment().subtract(daysBack, 'days').format('YYYY-MM-DD');
        setStartDate(newStartDate);
    };

    useEffect(() => {
        console.log('START DATE', startDate);
    }, [startDate]);

    return (
        <>
            {loader && <Loader />}
            <Content>
                <div className="period-container">
                    {t('pages.reports.period').text}:
                    <span
                        className={`period ${selectedPeriod === '1 mesec' ? 'active' : ''}`}
                        onClick={() => handlePeriodClick('1 mesec')}
                    >
                        {t('pages.reports.charts.one_month').text}
                    </span>
                    <span
                        className={`period ${selectedPeriod === '3 meseca' ? 'active' : ''}`}
                        onClick={() => handlePeriodClick('3 meseca')}
                    >
                        {t('pages.reports.charts.three_months').text}
                    </span>
                    <span
                        className={`period ${selectedPeriod === '6 meseci' ? 'active' : ''}`}
                        onClick={() => handlePeriodClick('6 meseci')}
                    >
                        {t('pages.reports.charts.six_months').text}
                    </span>
                    <span
                        className={`period ${selectedPeriod === '12 meseci' ? 'active' : ''}`}
                        onClick={() => handlePeriodClick('12 meseci')}
                    >
                        {t('pages.reports.charts.twelve_months').text}
                    </span>
                </div>
            </Content>
        </>
    );
};

const Content = styled.div`
    margin-top: 20px;
    h3 {
        text-align: center;
        margin-bottom: 26px;
        font-size: 20px;
        @media screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
            text-align: left;
        }
    }
    p {
        text-align: left;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .period-container {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        margin-top: 10px;
        flex-wrap: wrap;
        gap: 1rem;
        margin-bottom: 1.5rem;
        @media screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
            justify-content: left;
        }
    }

    .period {
        padding: 5px 10px;
        border: 1px solid #ccc;
        cursor: pointer;
        text-align: center;
    }

    .period:hover {
        background-color: #f0f0f0;
    }
    .period.active {
        background-color: var(--purple);
        color: #fff;
    }

    .values {
        margin-top: 2.5rem;
        p {
            color: var(--gray);
            font-size: 15px;
        }
    }
`;

const NoData = styled.div`
    margin-top: 50px;
    padding: 20px;
    text-align: center;
    margin-bottom: 100px;
`;

export default PeriodFilters;
