import React, { FunctionComponent, useEffect, useState } from 'react';
import { ObjectKeys } from '../../../types/objectKeys';
import Pagination from '../../../components/pagination';
import styled from 'styled-components';
import useLanguageStore from '../../../store/language';
import useTranslations from '../../../hooks/useTranslation';
import useAllUsersStore from '../../../store/admin/allUsers';
import communication from '../../../communication';
import Loader from '../../../components/loader';
import SelectComponent from '../../../components/select';
import Sidebar from '../../../components/sideModal';
import Button, { ButtonVariant } from '../../../components/button';
import colors from '../../../global/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faClose, faFileInvoice } from '@fortawesome/free-solid-svg-icons';
import Input, { InputComponentType } from '../../../components/input';
import moment from 'moment';
import SearchCustomComponent from '../../../components/customSelect';
import useUserStore from '../../../store/user';
import useCharLengthValidation from '../../../hooks/useCharLengthValidation';
import ErrorModal from '../../../components/errorModal';
import Modal from '../../../components/modal';
import { formatTwoDecimals } from '../../../functions/format';
import { usePasswordProtect } from '../../../hooks/passwordProtect';
import ConfirmModal from '../../../components/confirmModal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MQ_BREAKPOINTS } from '../../../constants/breakpoints';

type NewTransactionTypes = {
    amount: string;
    transaction_date: Date | undefined | null | string;
    type: string;
    package: string;
    user_id: string;
};
type NewTransactionValidationTypes = {
    amount: string;
    transaction_date: string;
    type: string;
    package: string;
    user_id: string;
};

const Transactions: FunctionComponent = () => {
    usePasswordProtect(['/admin/transactions']);
    const { currentLang } = useLanguageStore();
    const { userBOid } = useUserStore();
    const t = useTranslations(currentLang);
    const { allUsers } = useAllUsersStore();
    const transactionDataInitial = {
        amount: '830',
        transaction_date: new Date().toISOString().split('T')[0],
        type: 'payment',
        package: '1',
        user_id: '',
    };

    const validatorDataInitial = {
        amount: '',
        transaction_date: '',
        type: '',
        package: '',
        user_id: '',
    };
    const [loaderVisible, setLoaderVisible] = useState(false);

    const [addTransactionModal, setAddTransactionModal] = useState(false);
    const [editTransactionModal, setEditTransactionModal] = useState(false);

    const [addCustomTransactionModal, setAddCustomTransactionModal] = useState(false);

    const [page, setPage] = useState('1');
    const [allUsersOptions, setAllUsersOptions] = useState<Array<{ value: string; label: string; id: string }>>([]);
    const [selectedUserId, setSelectedUserId] = useState(userBOid ? userBOid : '');
    const [selectedTransactionId, setSelectedTransactionId] = useState('');
    const [selectedType, setSelectedType] = useState<string>('');
    const [transactions, setTransactions] = useState<ObjectKeys>([]);
    const [editTransactionDate, setEditTransactionDate] = useState<string>('');
    const [selectedUser, setSelectedUser] = useState<ObjectKeys>({});
    const [openErrorModal, setOpenErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [renderKey, setRenderKey] = useState(0);

    const [packages, setPackages] = useState<ObjectKeys[]>([]);
    const [selectedPackageOption, setSelectedPackageOption] = useState<ObjectKeys>(packages[0]);
    const [cart, setCart] = useState<ObjectKeys>({
        user_id: selectedUserId ? selectedUserId : '',
        status: '',
        total_price: 0,
        type: 'package',
        payment_type: 'transfer',
        note: '',
        is_upgrade: false,
        items: [
            {
                id: '',
                type: 'package',
                price: 0,
                months: 1,
            },
        ],
    });

    const [manualTransactionData, setManualTransactionData] = useState<ObjectKeys>({
        user_id: selectedUserId ? selectedUserId : '',
        package_id: '',
        amount: '',
        months: 1,
        transaction_date: '',
    });

    const [confirmSendModal, setConfirmSendModal] = useState(false);

    const [selectedUserInfo, setSelectedUserInfo] = useState<ObjectKeys>({});
    const [leftOverAmount, setLeftOverAmount] = useState<number | null>(null);

    const resetManualData = (): void => {
        setManualTransactionData({
            user_id: selectedUserId ? selectedUserId : '',
            package_id: '',
            amount: '',
            months: 1,
            transaction_date: '',
        });
    };

    const [isUpgrade, setIsUpgrade] = useState(false);

    const [errorManualData, setErrorManualData] = useState(false);
    const [negativeAmountError, setNegativeAmountError] = useState(false);

    const today = new Date();

    const [resendModal, setResendModal] = useState(false);
    const [clickedCartId, setClickedCartId] = useState('');

    const handleResendFiscalBill = (id: string): void => {
        setClickedCartId(id);
        setResendModal(true);
    };

    const generateFiscalBill = (): void => {
        setLoaderVisible(true);
        communication
            .resendFiscalBill(clickedCartId)
            .then(() => {
                updateData();
                setLoaderVisible(false);
            })
            .catch((error: any) => {
                console.error(error);
                updateData();
                setLoaderVisible(false);
            });
    };

    useEffect(() => {
        if (selectedPackageOption?.name !== 'Basic') {
            setPackageOptions([
                { value: 6, label: '6' },
                { value: 12, label: '12' },
            ]);
        } else {
            setPackageOptions([
                { value: 1, label: '1' },
                { value: 6, label: '6' },
                { value: 12, label: '12' },
            ]);
        }
    }, [selectedPackageOption]);

    useEffect(() => {
        setRenderKey((prev) => prev + 1);
    }, [cart.items[0].months]);

    const [newTransactionData, setNewTransactionData] = useState<NewTransactionTypes>(transactionDataInitial);

    const [newTransactionDataValidation, setNewTransactionDataValidation] =
        useState<NewTransactionValidationTypes>(validatorDataInitial);

    const types = [
        { value: 'all', label: 'All' },
        { value: 'transfer', label: 'Transfer' },
        { value: 'credit_card', label: 'Credit Card' },
    ];
    const [packageOptions, setPackageOptions] = useState([
        { value: 1, label: '1' },
        { value: 6, label: '6' },
        { value: 12, label: '12' },
    ]);

    const monthsOptions = [
        { value: 1, label: '1' },
        { value: 2, label: '2' },
        { value: 3, label: '3' },
        { value: 4, label: '4' },
        { value: 5, label: '5' },
        { value: 6, label: '6' },
        { value: 7, label: '7' },
        { value: 8, label: '8' },
        { value: 9, label: '9' },
        { value: 10, label: '10' },
        { value: 11, label: '11' },
        { value: 12, label: '12' },
    ];

    const getAllPackages = (): void => {
        setLoaderVisible(true);
        communication
            .getAllPackages()
            .then((response: ObjectKeys) => {
                setPackages(response.data.data);
                setSelectedPackageOption(response.data.data[0]);
                setCart({
                    ...cart,
                    items: cart.items.map((prev: ObjectKeys) => ({
                        ...prev,
                        id: response.data.data[0].id,
                        price: response.data.data[0].monthly,
                        months: response.data.data[0].name === 'Basic' ? 1 : 6,
                    })),
                });
                setLoaderVisible(false);
            })
            .catch((error: any) => {
                setLoaderVisible(false);
                console.error('Error:', error);
            });
    };

    useEffect(() => {
        getAllPackages();
    }, []);

    useEffect(() => {
        if (selectedPackageOption) {
            const item = cart.items[0];
            const discount =
                item.months === 6
                    ? selectedPackageOption.half_year_discount
                    : item.months === 12
                    ? selectedPackageOption.year_discount
                    : 0;
            const period = item.months;
            setCart({
                ...cart,
                total_price:
                    selectedPackageOption.monthly * period - (selectedPackageOption.monthly * period * discount) / 100,
            });
        }
    }, [cart.items]);

    useEffect(() => {
        const userData = allUsers?.map((user) => {
            return { value: user.id, label: user.email + '-' + user.pib, id: user.id };
        });
        if (userBOid === '' || userBOid === 'All users') useUserStore.setState({ userBOid: userData[0]?.value });
        const allUsersOption = { value: '', label: 'Svi korisnici', id: '' };
        setAllUsersOptions([allUsersOption, ...userData]);
    }, [allUsers]);

    useEffect(() => {
        setSelectedUserId(userBOid);
    }, [userBOid]);

    const updateData = async (): Promise<void> => {
        setLoaderVisible(true);
        if (selectedUserId.length > 0) {
            try {
                await communication
                    .getFilteredTransaction(selectedUserId, selectedType, page)
                    .then((res: ObjectKeys) => {
                        if (res.status === 200) {
                            getUserById(selectedUserId);
                            setTransactions(res?.data);
                            setLoaderVisible(false);
                        } else {
                            setLoaderVisible(false);
                        }
                    });
            } catch (err: any) {
                setErrorMessage(err?.response?.data?.message);
                setOpenErrorModal(true);
                setLoaderVisible(false);
            }
        } else {
            try {
                await communication.getAllTransactions(page, selectedType).then((response: ObjectKeys) => {
                    if (response.status === 200) {
                        setTransactions(response?.data);
                        setLoaderVisible(false);
                    } else {
                        setLoaderVisible(false);
                    }
                });
            } catch (err: any) {
                setErrorMessage(err?.response?.data?.message);
                setOpenErrorModal(true);
                setLoaderVisible(false);
            }
        }
    };

    const getUserById = (id: string): void => {
        communication
            .getUsersById(id)
            .then((res: ObjectKeys) => {
                if (res.status === 200) {
                    setSelectedUserInfo(res.data.data);
                    setLeftOverAmount(res.data.data.upgrade_package_data.upgrade_left_over_amount);
                }
            })
            .catch((error: ObjectKeys) => {
                if (error) {
                    setSelectedUserInfo({});
                    setLeftOverAmount(null);
                    toast.error('Korisnik sa unetim ID-em ne postoji');
                }
            });
    };

    useEffect(() => {
        updateData();
    }, [page, selectedType]);

    useEffect(() => {
        if (!addTransactionModal) {
            setNegativeAmountError(false);
        }
    }, [addTransactionModal]);

    useEffect(() => {
        setPage('1');
        updateData();
        if (selectedUserId) {
            getUserById(selectedUserId);
            if (!addTransactionModal) {
                setSelectedPackageOption(packages[0]);
                setCart({
                    ...cart,
                    user_id: selectedUserId,
                    items: cart.items.map((prev: ObjectKeys) => ({
                        ...prev,
                        id: selectedPackageOption?.id,
                        price: selectedPackageOption?.monthly,
                        months: selectedPackageOption?.name === 'Basic' ? 1 : 6,
                    })),
                });
            }
        }
    }, [selectedUserId]);

    const handlePageClick = (e: any): void => {
        setPage(e.selected + 1);
    };
    const [showError, setShowError] = useState(false);

    const isUserIdValid = useCharLengthValidation(newTransactionData?.user_id ? newTransactionData?.user_id : '', 6);
    // const isAmountValid = useCharLengthValidation(newTransactionData?.amount ? newTransactionData?.amount : '', 1);
    const isDateValid =
        newTransactionData.transaction_date === undefined || newTransactionData.transaction_date === null
            ? 'Datum je obavezan'
            : '';
    // const isTypeValid = newTransactionData?.type === '' ? 'Izaberite tip pretplate' : '';
    // const isPackageValid = newTransactionData?.package === '' ? 'Izaberite paket' : '';
    const editTransactionDateValid = editTransactionDate === '' ? 'Datum je obavezan' : '';

    const resetData = (): void => {
        setNewTransactionData(transactionDataInitial);
        setNewTransactionDataValidation(validatorDataInitial);
        setEditTransactionDate('');
        setSelectedTransactionId('');
        setShowError(false);
        setSelectedUser({});
    };

    useEffect(() => {
        setIsUpgrade(selectedUserInfo.trial || isDowngrade(cart.items[0].id) ? false : true);
    }, [cart.items[0].id]);

    const handleCreateNewTransaction = async (): Promise<void> => {
        setLoaderVisible(true);
        const updatedCart = {
            ...cart,
            total_price: isUpgrade ? cart.total_price - (leftOverAmount ?? 0) : cart.total_price,
            items: cart.items.map((prev: ObjectKeys) => ({
                ...prev,
                price: isUpgrade ? cart.total_price - (leftOverAmount ?? 0) : cart.total_price,
            })),
            is_upgrade: isUpgrade,
        };
        if (updatedCart.total_price <= 0 || updatedCart.items[0].price <= 0) {
            setNegativeAmountError(true);
            setLoaderVisible(false);
            return;
        }
        setNegativeAmountError(false);
        await communication.createCart(updatedCart).then((res: ObjectKeys) => {
            if (res.status === 201) {
                const formData = {
                    cart_id: res.data.data.id,
                    transaction_date: newTransactionData.transaction_date,
                };
                communication.createTransactionFromCart(formData).then((response: ObjectKeys) => {
                    if (response.status === 200) {
                        setAddTransactionModal(false);
                        resetData();
                        updateData();
                        setLoaderVisible(false);
                    } else {
                        setErrorMessage(response?.data?.message);
                        setOpenErrorModal(true);
                        setLoaderVisible(false);
                    }
                });
            }
        });
        setLoaderVisible(false);
    };

    const handleCreateManualTransaction = async (): Promise<void> => {
        if (
            !manualTransactionData.user_id ||
            !manualTransactionData.package_id ||
            !manualTransactionData.amount ||
            !manualTransactionData.months ||
            !manualTransactionData.transaction_date
        ) {
            setErrorManualData(true);
            return;
        }
        setLoaderVisible(true);
        setAddCustomTransactionModal(false);
        await communication.createManualTransaction(manualTransactionData).then((response: ObjectKeys) => {
            if (response.status === 200) {
                updateData();
                setAddCustomTransactionModal(false);
                toast.success(response.data[0]);
                resetManualData();
                setLoaderVisible(false);
            } else {
                setErrorMessage(response?.data?.message);
                setOpenErrorModal(true);
                setLoaderVisible(false);
            }
        });
    };

    const handleUpdateTransaction = (): void => {
        if (!editTransactionDate) {
            setShowError(true);
            return;
        } else {
            setShowError(false);
            setLoaderVisible(true);
            communication.updateTransaction(selectedTransactionId, editTransactionDate).then((res: ObjectKeys) => {
                if (res.status === 200) {
                    setEditTransactionModal(false);
                    resetData();
                    updateData();
                    setLoaderVisible(false);
                }
            });
        }
    };
    const handleDownloadClick = (url: string): void => {
        window.open(url, '_blank');
    };

    const compareDates = (dateStr1: string, dateStr2: string): boolean => {
        if (!dateStr1 || !dateStr2) return false;
        const date1 = new Date(dateStr1);
        const date2 = new Date(dateStr2);

        // Convert dates to ISO strings (UTC timezone) for accurate comparison
        const isoDate1 = date1.toISOString();
        const isoDate2 = date2.toISOString();

        // Compare the ISO date strings
        return isoDate1 === isoDate2;
    };

    const isDowngrade = (id: string): boolean => {
        if (!selectedUserInfo.payment_package) return false;
        const userPackageMonthly = selectedUserInfo.payment_package.data.monthly;
        const packageOption = packages.find((item: ObjectKeys) => item.id === id);
        const response = packageOption ? packageOption.monthly < userPackageMonthly : false;
        return response;
    };

    // const handleDeleteTransaction = (id: string): void => {
    //     setLoaderVisible(true);
    //     try {
    //         communication
    //             .deleteTransaction(id)
    //             .then((res: ObjectKeys) => {
    //                 if (res.status === 200 || res.status === 202) {
    //                     setEditTransactionModal(false);
    //                     resetData();
    //                     updateData();
    //                     setLoaderVisible(false);
    //                 } else {
    //                     // Handle other status codes if needed
    //                     setErrorMessage('Failed to delete transaction.');
    //                     setOpenErrorModal(true);
    //                     setLoaderVisible(false);
    //                 }
    //             })
    //             .catch((err: any) => {
    //                 setErrorMessage(err?.response?.data?.message);
    //                 setOpenErrorModal(true);
    //                 setLoaderVisible(false);
    //             });
    //     } catch (err: any) {
    //         setErrorMessage(err?.response?.data?.message);
    //         setOpenErrorModal(true);
    //         setLoaderVisible(false);
    //     }
    // };

    return (
        <>
            {loaderVisible && <Loader />}
            <ToastContainer />
            {resendModal && (
                <Modal
                    width="500px"
                    className="package-modal"
                    modalVisible={true}
                    closeModal={() => {
                        setResendModal(false);
                    }}
                >
                    <ConfirmModal
                        action={() => {
                            setResendModal(false);
                            generateFiscalBill();
                        }}
                        close={() => {
                            setResendModal(false);
                        }}
                        active={resendModal}
                        message={'Da li ste sigurni da zelite da izdate fiskalni racun?'}
                    />
                </Modal>
            )}
            {confirmSendModal && (
                <Modal modalVisible={confirmSendModal} closeModal={() => setConfirmSendModal(false)}>
                    <ConfirmModal
                        action={async () => {
                            setConfirmSendModal(false);
                            handleCreateNewTransaction();
                        }}
                        close={() => {
                            setAddTransactionModal(false);
                            setConfirmSendModal(false);
                        }}
                        active={confirmSendModal}
                        message={'Da li ste sigurni da zelite da izvršite transakciju?'}
                    />
                </Modal>
            )}
            {openErrorModal && (
                <Modal modalVisible={openErrorModal} closeModal={() => setOpenErrorModal(false)}>
                    <ErrorModal t={t} setOpenModal={setOpenErrorModal} errorMessage={errorMessage} />
                </Modal>
            )}
            <PageWrapper className=" page">
                {editTransactionModal && (
                    <Sidebar close={() => setEditTransactionModal(false)}>
                        <SidebarContent>
                            <div className="header">
                                <h1>Uredi transakciju</h1>
                                <button className={'close_button'} onClick={() => setEditTransactionModal(false)} />
                            </div>
                            <Input
                                type={InputComponentType.Date}
                                label={'Datum'}
                                validation={showError ? editTransactionDateValid : ''}
                                date={!editTransactionDate ? null : moment(editTransactionDate).toDate()}
                                onChange={(value: Date | undefined | null) =>
                                    setEditTransactionDate(moment(value).format('YYYY-MM-DD').toString())
                                }
                            />
                            <div className={'button-wrapper'}>
                                <Button
                                    variant={ButtonVariant.solid}
                                    color={colors.purple}
                                    onClick={() => {
                                        handleUpdateTransaction();
                                    }}
                                >
                                    Uredi Transakciju
                                </Button>
                            </div>
                        </SidebarContent>
                    </Sidebar>
                )}
                {/* Add transaction modal */}
                {addTransactionModal && (
                    <Sidebar
                        close={() => {
                            setAddTransactionModal(false);
                            resetData();
                        }}
                    >
                        <SidebarContent>
                            <div className="header">
                                <h1>Dodaj transakciju</h1>
                                <button
                                    className={'close_button'}
                                    onClick={() => {
                                        setAddTransactionModal(false);
                                        resetData();
                                    }}
                                />
                            </div>
                            <div className="content">
                                <p className="user-select">{t('pages.admin.users.chooseUser').text}:</p>
                                <div className="choose-user">
                                    <SearchCustomComponent
                                        value={allUsersOptions.find((v) => v.value === userBOid)}
                                        allUsersOptions={allUsersOptions}
                                        handleSelectedValue={(data: { value: string; label: string }) => {
                                            useUserStore.setState({ userBOid: data.value });
                                            setSelectedUserId(data.value);
                                            setSelectedPackageOption(packages[0]);
                                            setNewTransactionData((prevState) => ({
                                                ...prevState,
                                                user_id: data.value,
                                            }));
                                            setCart({
                                                ...cart,
                                                user_id: data.value,
                                                items: cart.items.map((prev: ObjectKeys) => ({
                                                    ...prev,
                                                    id: selectedPackageOption.id,
                                                    price: selectedPackageOption.monthly,
                                                    months: selectedPackageOption.name === 'Basic' ? 1 : 6,
                                                })),
                                            });
                                            setRenderKey((prev) => prev + 1);
                                        }}
                                        placeholder={t('pages.admin.users.chooseUser').text}
                                        className="user-select-transaction"
                                    />
                                </div>
                                {showError && isUserIdValid !== '' && (
                                    <span style={{ color: 'red', fontSize: '12px' }}>{isUserIdValid}</span>
                                )}
                                {selectedUser.pib && (
                                    <span className={'selected-user'}>
                                        {selectedUser.pib} - {selectedUser.email}{' '}
                                        <FontAwesomeIcon
                                            icon={faClose}
                                            color={colors.gray}
                                            className="delete-selected-user"
                                            onClick={() => setSelectedUser({})}
                                        />
                                    </span>
                                )}
                                {selectedUserId ? (
                                    <div className="create-transaction-form">
                                        <Packages>
                                            <p className="user-select">ODABERI PAKET:</p>
                                            <div className="packages">
                                                {packages.map((item, index) => (
                                                    <Package
                                                        key={index}
                                                        isSelected={item.id === selectedPackageOption.id}
                                                        onClick={() => {
                                                            setSelectedPackageOption(packages[index]);
                                                            setCart({
                                                                ...cart,
                                                                items: cart.items.map((prev: ObjectKeys) => ({
                                                                    ...prev,
                                                                    id: packages[index].id,
                                                                    type: 'package',
                                                                    price: packages[index].monthly,
                                                                    months: packages[index].name === 'Basic' ? 1 : 6,
                                                                })),
                                                            });
                                                        }}
                                                    >
                                                        {item.name}
                                                    </Package>
                                                ))}
                                            </div>
                                        </Packages>
                                        <p className="user-select">ODABERI PERIOD (MESECI):</p>
                                        <SelectComponent
                                            value={cart.items[0].months}
                                            key={renderKey}
                                            optionList={packageOptions}
                                            handleSelectedValue={(value: string) => {
                                                setCart({
                                                    ...cart,
                                                    items: cart.items.map((item: ObjectKeys) => ({
                                                        ...item,
                                                        months: value,
                                                    })),
                                                });
                                            }}
                                        />
                                        {/* {leftOverAmount !== null ? (
                                            <Input
                                                type={InputComponentType.Amount}
                                                validation={''}
                                                label={''}
                                                value={formatTwoDecimals(leftOverAmount.toString())}
                                                readOnly={true}
                                            />
                                        ) : null} */}
                                        <p className="user-select">UPLAĆENO (RSD):</p>
                                        {leftOverAmount !== null ? (
                                            <p className="total-amount">
                                                {formatTwoDecimals(leftOverAmount.toString())}
                                            </p>
                                        ) : null}
                                        <p className="user-select">IZNOS (RSD):</p>
                                        <p className="total-amount">{formatTwoDecimals(cart.total_price.toString())}</p>
                                        <p className="user-select">UKUPAN IZNOS (RSD):</p>
                                        {isUpgrade ? (
                                            <p className="total-amount">
                                                {formatTwoDecimals(
                                                    (cart.total_price - (leftOverAmount ?? 0)).toString(),
                                                )}
                                            </p>
                                        ) : (
                                            <p className="total-amount">
                                                {formatTwoDecimals(cart.total_price.toString())}
                                            </p>
                                        )}
                                        <Input
                                            type={InputComponentType.Date}
                                            label={'Datum transakcije:'}
                                            validation={showError ? isDateValid : ''}
                                            date={
                                                !newTransactionData.transaction_date
                                                    ? null
                                                    : moment(newTransactionData.transaction_date).toDate()
                                            }
                                            onChange={(value: Date | undefined | null) =>
                                                setNewTransactionData((prevState) => ({
                                                    ...prevState,
                                                    transaction_date: moment(value).format('YYYY-MM-DD').toString(),
                                                }))
                                            }
                                            maxDate={new Date()}
                                        />
                                        <div className={'button-wrapper'}>
                                            <Button
                                                variant={ButtonVariant.solid}
                                                color={colors.purple}
                                                onClick={() => {
                                                    if (cart.total_price - (leftOverAmount ?? 0) <= 0) {
                                                        setNegativeAmountError(true);
                                                        return;
                                                    } else {
                                                        setAddTransactionModal(false);
                                                        setConfirmSendModal(true);
                                                    }
                                                }}
                                            >
                                                Dodaj Transakciju
                                            </Button>
                                        </div>
                                        {negativeAmountError ? (
                                            <p className="error">Iznos mora biti veći od 0</p>
                                        ) : null}
                                        <p className="error">
                                            Upozorenje: za kreiranu transakciju BIĆE izdat fiskalni račun.
                                        </p>
                                    </div>
                                ) : (
                                    <p>Izaberi korisnika</p>
                                )}
                            </div>
                        </SidebarContent>
                    </Sidebar>
                )}
                {/* End of add transaction modal */}
                {/* Add custom transaction modal */}
                {addCustomTransactionModal && (
                    <Sidebar
                        close={() => {
                            setAddCustomTransactionModal(false);
                            resetManualData();
                        }}
                    >
                        <SidebarContent>
                            <div className="header">
                                <h1>Produži pretplatu</h1>
                                <button
                                    className={'close_button'}
                                    onClick={() => {
                                        setAddCustomTransactionModal(false);
                                        resetManualData();
                                    }}
                                />
                            </div>
                            <div className="content">
                                <p className="user-select">{t('pages.admin.users.chooseUser').text}:</p>
                                <div className="choose-user">
                                    <SearchCustomComponent
                                        value={allUsersOptions.find((v) => v.value === userBOid)}
                                        allUsersOptions={allUsersOptions}
                                        handleSelectedValue={(data: { value: string; label: string }) => {
                                            useUserStore.setState({ userBOid: data.value });
                                            setSelectedUserId(data.value);
                                            setManualTransactionData((prevState) => ({
                                                ...prevState,
                                                user_id: data.value,
                                            }));
                                            setSelectedPackageOption(packages[0]);
                                            setRenderKey((prev) => prev + 1);
                                        }}
                                        placeholder={t('pages.admin.users.chooseUser').text}
                                        className="user-select-transaction"
                                    />
                                </div>
                                {showError && isUserIdValid !== '' && (
                                    <span style={{ color: 'red', fontSize: '12px' }}>{isUserIdValid}</span>
                                )}
                                {selectedUserId ? (
                                    <div className="create-transaction-form">
                                        <Packages>
                                            <p className="user-select">ODABERI PAKET:</p>
                                            <div className="packages">
                                                {packages.map((item, index) => (
                                                    <Package
                                                        key={index}
                                                        isSelected={item.id === manualTransactionData.package_id}
                                                        onClick={() => {
                                                            setManualTransactionData((prevState) => ({
                                                                ...prevState,
                                                                package_id: packages[index].id,
                                                            }));
                                                        }}
                                                    >
                                                        {item.name}
                                                    </Package>
                                                ))}
                                            </div>
                                        </Packages>
                                        <p className="user-select">ODABERI PERIOD (MESECI):</p>
                                        <SelectComponent
                                            value={cart.items[0].months}
                                            key={renderKey}
                                            optionList={monthsOptions}
                                            handleSelectedValue={(value: string) => {
                                                setManualTransactionData((prevState) => ({
                                                    ...prevState,
                                                    months: parseInt(value),
                                                }));
                                            }}
                                        />
                                        <Input
                                            type={InputComponentType.Amount}
                                            validation={''}
                                            label={'IZNOS (RSD)'}
                                            value={manualTransactionData.amount}
                                            readOnly={false}
                                            onChange={(e: any) => {
                                                setManualTransactionData((prevState) => ({
                                                    ...prevState,
                                                    amount: e,
                                                }));
                                            }}
                                        />
                                        <Input
                                            type={InputComponentType.Date}
                                            label={'Datum transakcije:'}
                                            validation={showError ? isDateValid : ''}
                                            date={
                                                !manualTransactionData.transaction_date
                                                    ? null
                                                    : moment(manualTransactionData.transaction_date).toDate()
                                            }
                                            onChange={(value: Date | undefined | null) =>
                                                setManualTransactionData((prevState) => ({
                                                    ...prevState,
                                                    transaction_date: moment(value).format('YYYY-MM-DD').toString(),
                                                }))
                                            }
                                            maxDate={new Date()}
                                        />
                                        {errorManualData ? <p className="error">Sva polja su obavezna</p> : null}
                                        <div className={'button-wrapper'}>
                                            <Button
                                                variant={ButtonVariant.solid}
                                                color={colors.purple}
                                                onClick={() => handleCreateManualTransaction()}
                                            >
                                                Produži pretplatu
                                            </Button>
                                        </div>
                                        <p className="warning">
                                            Upozorenje: za kreiranu transakciju NEĆE BITI izdat fiskalni račun.
                                        </p>
                                    </div>
                                ) : (
                                    <p>Izaberi korisnika</p>
                                )}
                            </div>
                        </SidebarContent>
                    </Sidebar>
                )}
                {/* End of add custom transaction modal */}
                <Header>
                    <h1>{t('pages.admin.transactions.title').text}</h1>
                    <div>
                        <Button
                            className={'create-button'}
                            variant={ButtonVariant.solid}
                            color={colors.purple}
                            onClick={() => {
                                setAddCustomTransactionModal(true);
                                setManualTransactionData((prevState) => ({
                                    ...prevState,
                                    user_id: userBOid,
                                }));
                            }}
                            icon={true}
                        >
                            <FontAwesomeIcon style={{ paddingLeft: '10px' }} color={colors.white} icon={faPlus} />
                            Produži pretplatu
                        </Button>
                        <Button
                            className={'create-button'}
                            variant={ButtonVariant.solid}
                            color={colors.purple}
                            onClick={() => {
                                setAddTransactionModal(true);
                                setNewTransactionData((prevState) => ({
                                    ...prevState,
                                    user_id: userBOid,
                                }));
                            }}
                            icon={true}
                        >
                            <FontAwesomeIcon style={{ paddingLeft: '10px' }} color={colors.white} icon={faPlus} />
                            Nova Transakcija
                        </Button>
                    </div>
                </Header>
                <SelectWrapper>
                    <div className="agency-select-wrapper">
                        <label>{t('pages.admin.users.chooseUser').text}</label>
                        <div className="select-container">
                            <SearchCustomComponent
                                value={allUsersOptions.find((v) => v.value === userBOid)}
                                allUsersOptions={allUsersOptions}
                                handleSelectedValue={(data: { value: string; label: string }) => {
                                    useUserStore.setState({ userBOid: data.value });
                                    setSelectedUserId(data.value);
                                }}
                                placeholder={t('pages.admin.users.chooseUser').text}
                                className="user-selector"
                            />
                        </div>
                    </div>
                    <div className="selecttype">
                        <label>TYPE: </label>
                        <SelectComponent
                            optionList={types}
                            defaultSelectedOption={types[0]}
                            handleSelectedValue={(value: string) => {
                                value === 'all' ? setSelectedType('') : setSelectedType(value);
                            }}
                        />
                    </div>
                </SelectWrapper>

                <TableContainer>
                    <StyledTable>
                        <TableHeader>
                            <tr>
                                <th>Email</th>
                                <th>PIB</th>
                                <th>Datum uplate</th>
                                <th>Datum isteka naloga</th>
                                <th>Paket</th>
                                <th>Iznos</th>
                                <th>Meseci</th>
                                <th>Način plaćanja</th>
                                {/* <th>Akcije</th> */}
                                <th>Račun</th>
                            </tr>
                        </TableHeader>
                        <TableBody>
                            {transactions?.data?.length > 0 &&
                                transactions?.data.map((transaction: ObjectKeys) => (
                                    <tr key={transaction.id}>
                                        <TableCell>{selectedUserInfo.email}</TableCell>
                                        <TableCell>{transaction.pib}</TableCell>
                                        <TableCell>
                                            {moment(transaction.transaction_date).format('DD.MM.yyyy')}
                                        </TableCell>
                                        <TableCell>
                                            {moment(selectedUserInfo.account_expiration_date).format('DD.MM.yyyy')}
                                        </TableCell>
                                        <TableCell>
                                            {transaction?.cart?.data?.items?.data[0]?.package?.data?.name || '-'}
                                        </TableCell>
                                        <TableCell>{formatTwoDecimals(transaction.amount.toString())}</TableCell>
                                        <TableCell>{transaction.package}</TableCell>
                                        <TableCell>{transaction?.cart?.data?.payment_type || 'trial'}</TableCell>
                                        {/* <TableCell>
                                            {transaction.cart ? null : (
                                                <ActionButtons>
                                                    <FontAwesomeIcon
                                                        className="icon"
                                                        color={colors.blue}
                                                        icon={faEdit}
                                                        onClick={() => {
                                                            setSelectedTransactionId(transaction.id);
                                                            setEditTransactionModal(true);
                                                        }}
                                                    />
                                                    <FontAwesomeIcon
                                                        className="icon"
                                                        color={colors.danger}
                                                        icon={faTrashCan}
                                                        onClick={() => handleDeleteTransaction(transaction.id)}
                                                    />
                                                </ActionButtons>
                                            )}
                                        </TableCell> */}
                                        {/* <TableCell>{transaction?.cart?.data?.invoice_pdf_url || '-'}</TableCell> */}
                                        {transaction?.cart?.data?.invoice_pdf_url ? (
                                            <TableCell
                                                onClick={() =>
                                                    handleDownloadClick(transaction.cart.data.invoice_pdf_url)
                                                }
                                            >
                                                <FontAwesomeIcon icon={faFileInvoice} className="billDownload" />
                                            </TableCell>
                                        ) : (
                                            <>
                                                {compareDates(
                                                    today.toISOString(),
                                                    transaction?.cart?.data?.created_at,
                                                ) ? (
                                                    <TableCell
                                                        onClick={(event) => {
                                                            event.stopPropagation();
                                                            handleResendFiscalBill(transaction?.cart?.data?.id);
                                                        }}
                                                    >
                                                        <p className="billDownload">Ponovi slanje</p>
                                                    </TableCell>
                                                ) : (
                                                    <TableCell>-</TableCell>
                                                )}
                                            </>
                                        )}
                                    </tr>
                                ))}
                        </TableBody>
                    </StyledTable>
                </TableContainer>

                {transactions?.data?.length > 0 && transactions.meta?.pagination?.total_pages > 1 && (
                    <Pagination
                        pageCount={transactions.meta?.pagination?.total_pages}
                        onPageChange={(e: object) => handlePageClick(e)}
                        nextLabel={`${
                            transactions.meta?.pagination?.current_page === transactions.meta?.pagination?.total_pages
                                ? ''
                                : '>'
                        }`}
                        previousLabel={`${transactions.meta?.pagination?.current_page === 1 ? '' : '<'}`}
                        breakLabel="..."
                        initialPage={transactions.meta?.pagination?.current_page - 1}
                    />
                )}
            </PageWrapper>
        </>
    );
};
const PageWrapper = styled.div``;
const Header = styled.div`
    border-bottom: 1px solid var(--border-color);
    padding-bottom: 20px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    .create-button {
        text-transform: uppercase;
        font-size: 12px;
        padding: 8px 20px;
        display: inline-block;
        vertical-align: top;
        margin: 4px 0 0 35px;
        border: 1px solid var(--purple);
        border-radius: 100px;
        cursor: pointer;
        background: var(--purple);
        color: var(--white);
    }
`;

const Packages = styled.div`
    p {
        color: var(--gray);
        margin-top: 20px;
    }
    .packages {
        display: flex;
        gap: 5px;
        padding: 10px;
        justify-content: space-evenly;
        @media only screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
            flex-direction: column;
            align-items: center;
        }
    }
`;

const Package = styled.div<{ isSelected: boolean }>`
    width: 100%;
    max-width: 150px;
    display: flex;
    justify-content: center;
    border: 1px solid var(--purple);
    font-size: 20px;
    padding: 20px;
    border-radius: 15px;
    cursor: pointer;
    background-color: ${(props) => (props.isSelected ? 'var(--purple)' : 'white')};
    color: ${(props) => (props.isSelected ? 'white' : 'var(--purple)')};
`;

const SidebarContent = styled.div`
    .button-wrapper {
        margin-top: 20px;
        display: flex;
        justify-content: center;
        button {
            border-radius: 100px;
        }
    }
    .header {
        position: relative;
        margin-bottom: 20px;
        h1 {
            font-size: 24px;
            color: #4c595f;
            font-weight: 400;
            display: inline;
        }
        .close_button {
            position: absolute;
            top: 10px;
            right: 10px;
            display: block;
            background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAfCAYAAACGVs+MAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAs1JREFUeNq0l81LVFEYxu+cJkkTG5hFIOmmFi6iRS6EKIoGYygbK/qQiMQ25UJ3NdBfENmmJCJa9EH0JSXaB1NiJBLVIok2FbQIowIhsJJRxsqeF56By3DeM/c6txd+szjv13Mu9555T+zlmw8Jz/POg13gGzgL+r3/Yz0gC5aDB6A3jp+LYD8DVoNzoAGciLj5KTYv2iFQZfDTZgk+TiGxCBrHWCtr8WVEwCfH47pQoYgYa/Qo/s8i4CT4owQcBVfAkkU0l5zLrGEz6ZkVAUPgIJhXAg+D6yAeonmcOZ2Kf549Bw0X7oC9oKAkdIDb8tIEaF7F2A7FLz32sadnfI5h0A5mlcQ94B5Y5mguvruMtdksewwVF0xJQA7sBHmlwA4KrbH4auhrU3LzrJ3zLxpL4ChIg59KoVbwCNT61mq51qrk/GLN0VKHURLGwTYwrfg3g8dgBclxzWbTFDauva2avQIp8AQkLf4Nvh01KzW+cyMTWhNT5o2WxK1gSvE3O5pPMXfC1cAE+Kze8vF+DXEOSOwW5nqVChB7TxGTAWInGfsuSGETYlcfeSqWs07GelELWAOuBYi7ythIBTSBMdAYILaRsU1RCVgLnoH6EE+rnjnrKhWwnoVWKv7XxGaS85Q1FiWghQdNUvG/4EElPFdikqzRElbARp6ACcU/xhPuB0lzzWYJ1toUVECKZ3udUnAEbAczvrUZro0oOXWsmSonQHZyn2OzzeQfL6P8Xefpe6jk1rB2WhOQ4aBQrRQYBLvBnOO9mfMNLjarZo/2UgFyLxhwjFy3GFMI8AlKzAHmaCPbQPEuEqeaG47JV06/I47J2Wa/efEoKMf3UvYsyBM442h+CXSFbO4fu7t489LG9tMiYJUS0M+Z/m8FFxPJ7XbcNRsM38xS65OLI1iI4Gq2wFp9Ft+w4S5v8jP6QsVRX0w91uxmjzx7HvsnwADMMpd5tpG+eAAAAABJRU5ErkJggg==)
                no-repeat;
            background-size: 16px 16px;
            width: 16px;
            height: 16px;
            text-indent: -9999px;
            border: 0;
            &:hover {
                cursor: pointer;
            }
        }
    }
    .choose-user {
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .user-select {
        font-size: 16px;
        color: var(--gray);
    }
    .total-amount {
        font-size: 18px;
        margin-bottom: 20px;
        margin-top: 10px;
        border: 1px solid var(--border-color);
        border-radius: 5px;
        padding: 10px;
    }
    .content {
        .select-search {
            margin-bottom: 20px;
        }
        .select-wrapper {
            width: 100%;
            label {
                font-size: 15px;
                color: var(--gray);
                padding-left: 0;
            }
            .select__control {
                margin-top: 5px;
                margin-bottom: 5px;
            }
        }
        .search-wrapper {
            margin-bottom: 5px;
            margin-top: 5px;
        }

        .options-list {
            z-index: 1;
            top: 9em;
        }
    }
    .selected-user {
        display: block;
        margin-top: 15px;
        margin-bottom: 20px;
        font-size: 15px;
        color: var(--gray);
        .delete-selected-user {
            float: right;
            cursor: pointer;
        }
    }
    .warning {
        color: red;
        font-size: 12px;
        margin-top: 10px;
    }
    .error {
        color: red;
        font-size: 14px;
        margin-top: 10px;
        text-align: center;
    }
`;

const SelectWrapper = styled.div`
    margin-bottom: 20px;
    &.full-width {
        > div {
            width: 100%;
        }
    }
    p {
        color: var(--gray);
    }
    .select-container {
        width: 350px;
        margin-left: 10px;
        @media only screen and (max-width: ${MQ_BREAKPOINTS.mobileL}) {
            width: 200px;
        }
    }
    .select-search {
        width: 100%;
        .selected-value,
        .select-with-search__input-container {
            color: var(--black);
        }

        div {
            font-size: 15px;
            color: var(--gray);
            .select-with-search__menu-list {
                .select-with-search__option {
                    &:hover {
                        color: var(--white);
                    }
                    &.select-with-search__option--is-selected {
                        color: var(--white);
                        border-radius: 0;
                    }
                }
            }
        }
    }
    .agency-select-wrapper {
        display: inline-block;
        width: 100%;
        margin-bottom: 25px;
        label {
            display: inline-block;
            color: #92a5ac;
            font-size: 16px;
            position: relative;
            top: 2px;
            margin-right: 10px;
        }
        .select-container {
            width: 350px;
            display: inline-block;
        }
    }
    .selecttype {
        display: flex;
        align-items: center;
        margin-left: 20px;

        label {
            text-transform: uppercase;
            line-height: 20px;
            font-size: 14px;
            margin-right: 6px;
            color: var(--gray);
        }
    }
`;
const TableContainer = styled.div`
    overflow-x: auto;
`;

const StyledTable = styled.table`
    width: 100%;
    border-collapse: collapse;
`;

const TableHeader = styled.thead`
    background-color: var(--purple);
    color: var(--white);

    th {
        font-weight: bold;
        padding: 10px;
        text-align: center;
        border-bottom: 1px solid #ccc;
    }
`;

const TableBody = styled.tbody`
    tr {
        border-bottom: 1px solid #ccc;

        &:last-child {
            border-bottom: none;
        }
    }
`;

const TableCell = styled.td`
    padding: 10px;
    text-align: center;
    .billDownload {
        cursor: pointer;
        color: var(--purple);
    }
`;

// const ActionButtons = styled.div`
//     display: flex;
//     justify-content: center;
//     gap: 10px; // Space between buttons
//     .icon {
//         cursor: pointer;
//     }
// `;

export default Transactions;
