import React from 'react';
import styled from 'styled-components';
import Doughnut from './doughnut';
import BarChartTop5 from './barChartTop5';
import BarChartTopDebtors from './barChartTopDebtors';
import { MQ_BREAKPOINTS } from '../../../constants/breakpoints';

const Charts: React.FC = () => {
    return (
        <SplitScreenContainer>
            <ChartSection>
                <BarChartTop5 />
            </ChartSection>
            <ChartSection>
                <BarChartTopDebtors />
            </ChartSection>
            <ChartSection>
                <Doughnut />
            </ChartSection>
        </SplitScreenContainer>
    );
};

const SplitScreenContainer = styled.div`
    display: flex;
    flex-wrap: wrap;

    @media only screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
        flex-direction: column;
        flex-wrap: nowrap;
    }
`;

const ChartSection = styled.div`
    flex: 1;
    padding: 20px;
    border-right: 1px solid #ccc;

    &:last-child {
        border-right: none;
    }

    @media screen and (max-width: ${MQ_BREAKPOINTS.tablet}) {
        padding: 20px 0;
        border-right: none;
    }
`;

export default Charts;
