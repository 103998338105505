import { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import useLanguageStore from '../../store/language';
import useTranslations from '../../hooks/useTranslation';
import communication from '../../communication';
import { ObjectKeys } from '../../types/objectKeys';
import moment from 'moment';
import Charts from './charts';
import Filters from './filters';
import Limits from './limits';
import { getAccessToken } from '../../functions/auth';
import useResponsive from '../../hooks/responsive/useResponsive';

interface FilterProps {
    start_date: string;
    end_date: string;
    type: string;
    client_id?: string;
    currency?: string;
}

const Dashboard: FunctionComponent = () => {
    const { isMobile } = useResponsive();
    const { currentLang } = useLanguageStore();
    const token = getAccessToken();
    const t = useTranslations(currentLang);
    const [loaderVisible, setLoaderVisible] = useState(false);
    const [page, setPage] = useState<number>(1);
    const [reportsData, setReportsData] = useState<ObjectKeys>([]);
    const [choosenDate, setChoosenDate] = useState<number>(1);
    const [props, setProps] = useState<FilterProps>({
        start_date: moment().startOf('year').format('YYYY-MM-DD'),
        end_date: moment().endOf('year').format('YYYY-MM-DD'),
        type: 'total',
    });

    useEffect(() => {
        setLoaderVisible(true);
        communication
            .getAllReports(page, props)
            .then((res: ObjectKeys) => {
                if (res.status === 200) {
                    setLoaderVisible(false);
                    setReportsData(res?.data);
                }
            })
            .catch((error: ObjectKeys) => {
                if (error) {
                    setLoaderVisible(false);
                    setReportsData([]);
                }
            });
    }, [page, props]);

    const handleFilterChange = (filters: FilterProps): void => {
        setPage(1);
        setProps(filters);
    };

    return (
        <>
            <Header>
                <h1>{t('pages.dashboard.title').text}</h1>
            </Header>
            <Content>
                <Limits />
                <Filters setChoosenDate={setChoosenDate} />
                <Charts />
            </Content>
        </>
    );
};

const Content = styled.div`
    margin-top: 5px;
`;
const Header = styled.div`
    border-bottom: 1px solid var(--border-color);
    padding-bottom: 20px;
`;
export default Dashboard;
